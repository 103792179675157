import React,{useState,useEffect} from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import logo from './logo.svg';
import './Console.css';
import PocketBase from 'pocketbase'
import { Primary, Secondary } from '../Constants/Colours';
import Logo from '../GlobalComponents/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice,faEnvelopeOpenText,faMobile,faPeopleGroup, faXmark, faSms,faGear, faHistory, faPencil, faListSquares,faHourglass } from '@fortawesome/free-solid-svg-icons'
import { faGoogle,faWhatsapp,  } from '@fortawesome/free-brands-svg-icons'
import { faCreditCard,faFile,faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import InvoiceGenerator from './Invoice';
import eventEmitter from '../GlobalComponents/EventEmitter';
import { motion,AnimatePresence } from 'framer-motion';
import Customers from './Customers';
import Settings from './Settings';
import History from './History';
import Invoices from './Invoices';
import Drafts from './Drafts';
import Approval from './Approval';
import InvLogo from '../GlobalComponents/InvLogo';




function Console() {
  const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase')
  const [activeSection,setActiveSection] = useState<any>('Invoice')
  const [modalOpen,setModalOpen] = useState(false)
  const [givenConsent,setGivenConsent] = useState(true)
  const [selecting,setSelecting] = useState(true)
  const [selectedOption,setSelectedOption] = useState('')
  const [invoiceIdentifier,setInvoiceIdentifier] = useState('')
  const [deleteID,setDeleteID] = useState('')
  const [email,setEmail] = useState('')
  const [invoiceSent,setInvoiceSent] = useState(false)

  const openModal = () => {
    setTimeout(() => {
      setModalOpen(true)
      eventEmitter.emit('ModalLoaded')
    }, 200);
  };

  const closeModal = () => {
    setTimeout(() => {
      setModalOpen(false)
      eventEmitter.emit('ModalLoaded')
      setSelecting(true)
      setSelectedOption('')
    }, 200);
  };

  const DeletedSignal = () => {
    const data = {
      id: deleteID
    }
    eventEmitter.emit('DeletedEmit',data)
    setDeleteID('')
  };

  const ModalSize = (selectedOption !== '' && !invoiceSent)

  const SendPaymentInvoice = async () => {
        const data = {
            id: invoiceIdentifier,
            email: email,
        };

        try {
          const response = await pb.collection('Invoices').getOne(invoiceIdentifier)
        
        const customer = await pb.collection('Customers').getOne(response.Customer)

        const updatecustomer = {
            'Email': email
        }

        await pb.collection('Customers').update(response.Customer,updatecustomer)

        fetch('https://admin.aaatheatingandgas.co.uk/api/send-invoice', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),  // Convert data to JSON string
        })
        } catch (error) {
            console.error('Error:',error)
        } finally {
          setInvoiceSent(true)
          setTimeout(() => {  
            closeModal()
            setInvoiceSent(false)
          },4500)    
    }
  }

  

  const SendPaymentInvoiceNP = async () => {
        const data = {
            id: invoiceIdentifier,
            email: email,
            review:givenConsent
        };

        try {
          const response = await pb.collection('Invoices').getOne(invoiceIdentifier)
        
        const customer = await pb.collection('Customers').getOne(response.Customer)

        const updatecustomer = {
            'Email': email
        }

        await pb.collection('Customers').update(response.Customer,updatecustomer)

        fetch('https://admin.aaatheatingandgas.co.uk/api/send-invoice-np', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),  // Convert data to JSON string
        })
        } catch (error) {
            console.error('Error:',error)
        } finally {
          setInvoiceSent(true)
          setTimeout(() => {  
            closeModal()
            
          },4500)   
          setTimeout(() => {  
            setInvoiceSent(false)
          },5000)    
    }
  }

  const handleEmailChange = (value:any) => {
    setEmail(value)
  }

  const checkForEmail = async (id : any) => {
    const response = await pb.collection('Invoices').getOne(id)
    const customer = await pb.collection('Customers').getOne(response.Customer)
    setEmail(customer.Email)
    

  }

  const ColourOption = (Section : any) => {
    return window.innerWidth < 1000 ? (activeSection == Section ?'#071224' : '#505867') : (activeSection == Section ?'#eff3f4' : '#505867')
  }

   const BackgroundOption = (Section : any) => {
    return window.innerWidth < 1000 ? (activeSection == Section ? 'transparent' : 'transparent') : (activeSection == Section ?'#071224' : 'transparent')
  }

  useEffect(() => {
    const handleIdentifierEvent = (data : any) => {
      setInvoiceIdentifier(data.id);  // Process received data
      checkForEmail(data.id)
    };

      eventEmitter.on('recieveID', handleIdentifierEvent);  // Listen for the event

    return () => {
      eventEmitter.off('recieveID', handleIdentifierEvent);  // Cleanup when the component unmounts
    };
  }, []);

  useEffect(() => {
    const handleDeleteEvent = (data : any) => {
      setDeleteID(data.id);  // Process received data
    };

      eventEmitter.on('deleteEvent', handleDeleteEvent);  // Listen for the event

    return () => {
      eventEmitter.off('deleteEvent', handleDeleteEvent);  // Cleanup when the component unmounts
    };
  }, []);

  useEffect(() => {
    eventEmitter.on('ToggleModal', openModal);

    // Clean up the event listener when the component unmounts
    return () => {
      eventEmitter.off('ToggleModal', openModal);
    };
  }, []);

  const handleSelect = (option:string) => {
    setSelecting(false)
    setTimeout(() => {
      setSelectedOption(option)
    }, 1000);
    
  }


 

  return (
   
    <>
    {deleteID !== '' && (
      <div className="Overlay">
        <motion.div layout initial={{scale:0.2,opacity:0.3}} animate={{scale:1,opacity:1,height:ModalSize ? '500px' : '430px'}} className='Modal'>
          <div className='Modal-Inner'>
          <div className='Close-Modal'>
            <motion.div whileTap={{scale:0.7}} onClick={closeModal} className='Modal-X'>
            <FontAwesomeIcon size='2x' color='#0000008a' icon={faXmark}/>
            </motion.div>
          </div>
          <h1 className='SI-Header'>Delete Invoice</h1>
          <motion.h1 initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} className='SI-Sub-Header'>Are you sure you want to delete this Invoice?</motion.h1>
          <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} exit={{y:'10%',opacity:0}} transition={{delay:0.6}} className='Send-Options-Container'>
              <motion.div onClick={DeletedSignal}  whileTap={{scale:0.7}} whileHover={{scale:1.1}}>
                <h1>Yes</h1>
              </motion.div>
              <motion.div whileTap={{scale:0.7}} whileHover={{scale:1.1}} onClick={() => setDeleteID('')}>
                <h1>No</h1>
              </motion.div>
            </motion.div>

          </div>
        </motion.div>     
      </div>
    )}
    
    {modalOpen && (<div className='Overlay'>
      <motion.div
        layout
        initial={{ scale: 0.2, opacity: 0.3, height: '370px' }}
        animate={{ scale: 1, opacity: 1, height: ModalSize ? '500px' : '370px' }}
        transition={{ duration: 0.5 }}
        className='Modal'
      >
        <div className='Modal-Inner'>
          <div className='Close-Modal'>
            <motion.div whileTap={{scale:0.7}} onClick={closeModal} className='Modal-X'>
            <FontAwesomeIcon size='2x' color='#0000008a' icon={faXmark}/>
            </motion.div>
          </div>
          <h1 className='SI-Header'>Send Invoice</h1>
          <AnimatePresence>
            {selecting &&
            (
              <motion.h1 initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:0.4}} exit={{y:'10%',opacity:0}} className='SI-Sub-Header'>Pick a Delivery Option for This Invoice</motion.h1>
            )
            }
          </AnimatePresence>
          <AnimatePresence>
            {(selectedOption == 'Email' && !invoiceSent) &&
            (
              <motion.h1 initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} className='SI-Sub-Header'>Enter the email address you wish to send to</motion.h1>
            )
            }

          </AnimatePresence>
          <AnimatePresence>
            {(selectedOption == 'SMS' && !invoiceSent) &&
            (
              <motion.h1 initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} exit={{y:'10%',opacity:0}} className='SI-Sub-Header'>Enter the number you wish to send to</motion.h1>
            )
            }
          </AnimatePresence>
          <AnimatePresence>
            {(selectedOption == 'WhatsApp' && !invoiceSent) &&
            (
              <motion.h1 initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} exit={{y:'10%',opacity:0}} className='SI-Sub-Header'>Enter the number you wish to send to</motion.h1>
            )
            }

          </AnimatePresence>
          <AnimatePresence>
            {invoiceSent &&
            (
              <motion.div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center'}} initial={{y:'-10%',opacity:0}}  animate={{y:'0%',opacity:1,transition:{delay:0.4}}} exit={{y:'10%',opacity:0}} >
              <motion.h1 className='SI-Sub-Header'>Invoice Sent to <br />{email}</motion.h1>
              <FontAwesomeIcon size='7x' color='#0f57fb' icon={faCheckCircle}/>
              </motion.div>
            )
            }

          </AnimatePresence>
          <AnimatePresence>
          {selecting && 
          (
           <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} exit={{y:'10%',opacity:0}} transition={{delay:0.6}} className='Send-Options-Container'>
              <motion.div onClick={() => handleSelect('Email')} whileTap={{scale:0.7}} whileHover={{scale:1.1}}>
                <FontAwesomeIcon size='3x' color='#0000008a' icon={faGoogle}/>
                <h1>Email</h1>
              </motion.div>
              <motion.div style={{opacity:0.4}}>
                <FontAwesomeIcon size='3x' color='#0000008a' icon={faWhatsapp}/>
                <h1>WhatsApp</h1>
              </motion.div>
              <motion.div style={{opacity:0.4}}>
                <FontAwesomeIcon size='3x' color='#0000008a' icon={faSms}/>
                <h1>SMS</h1>
              </motion.div>
           </motion.div>
           )
           }
            </AnimatePresence>
            <AnimatePresence>
              {(selectedOption == 'Email' && !invoiceSent) &&
                (
                  <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1,transition:{delay:0.3}}}  transition={{delay:0.3}} className='Send-Input-Container'>
                    <input defaultValue={email} onChange={(e) => handleEmailChange(e.target.value)} className='Email-Input' type='email' placeholder='Email Address'/>
                    
                  </motion.div>
                )
              }
            </AnimatePresence>
            <AnimatePresence>
            {(selectedOption !== '' && !invoiceSent) && 
            (
              <>
              <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1,transition:{delay:0.5} }} className="Consent-Row">
                  <motion.div whileTap={{scale:0.9}} whileHover={{scale:1.1}} onClick={givenConsent ? () => setGivenConsent(false):() => setGivenConsent(true)} className="Consent-Box">
                    {givenConsent && (<InvLogo size={0.35} color={Primary}/>)}
                  </motion.div>
                  <p>
                    Request Review
                    </p>
                </motion.div>
            <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1,transition:{delay:0.5} }}  className='Send-Button-Container'>
              <motion.div style={{backgroundColor:Primary,opacity:0.4}} className='Send-Button'>
                      <h1>Send <FontAwesomeIcon size='1x' color='#fff' icon={faCreditCard}/></h1>
              </motion.div>
              <motion.div onClick={SendPaymentInvoiceNP} style={{backgroundColor:Secondary}} whileHover={{scale:1.1}} whileTap={{scale:0.7}} className='Send-Button'>
                      <h1>Send without Payment Link <FontAwesomeIcon size='1x' color='#fff' icon={faFile}/></h1> 
              </motion.div>
            </motion.div>
            </>
            )}
            </AnimatePresence>
        </div>
      </motion.div>
    </div>)}
    <div className="Console">
      <div className="Side-Bar">
        <div className='Side-Bar-Inner'>
          {window.innerWidth > 1000 && ( 
          <Logo scale={1.5} colour='#071224'/>)}
          <div className='Line'></div>

          <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}}  onClick={() => setActiveSection('Invoice')} style={{backgroundColor:BackgroundOption('Invoice')}} className='Item'>
          <div>
          <FontAwesomeIcon size={window.innerWidth < 1000 ? '2x': '1x'} color={ColourOption('Invoice')} icon={faFileInvoice} /><h1 style={{color:ColourOption('Invoice')}}>Invoice Generator</h1>
          </div>
          </motion.div>

          <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:0.45}}  onClick={() => setActiveSection('Invoices')} style={{backgroundColor:BackgroundOption('Invoices')}} className='Item'>
          <div>
          <FontAwesomeIcon size={window.innerWidth < 1000 ? '2x': '1x'} color={ColourOption('Invoices')} icon={faListSquares} /><h1 style={{color:ColourOption('Invoices')}}>Invoices</h1>
          </div>
          </motion.div>

          <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:0.7}} onClick={() => setActiveSection('Customers')} style={{backgroundColor:BackgroundOption('Customers')}} className='Item'>
          <div>
          <FontAwesomeIcon  size={window.innerWidth < 1000 ? '2x': '1x'} color={ColourOption('Customers')} icon={faPeopleGroup}/><h1 style={{color:ColourOption('Customers')}}>Customers</h1>
          </div>
          </motion.div>

          <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:0.9}}  onClick={() => setActiveSection('History')} style={{backgroundColor:BackgroundOption('History')}} className='Item'>
          <div>
          <FontAwesomeIcon size={window.innerWidth < 1000 ? '2x': '1x'} color={ColourOption('History')} icon={faHistory} /><h1 style={{color:ColourOption('History')}}>Sent</h1>
          </div>
          </motion.div>


          <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:0.9}}  onClick={() => setActiveSection('Pending')} style={{backgroundColor:BackgroundOption('Pending')}} className='Item'>
          <div>
          <FontAwesomeIcon size={window.innerWidth < 1000 ? '2x': '1x'} color={ColourOption('Pending')} icon={faHourglass} /><h1 style={{color:ColourOption('Pending')}}>Pending Approval</h1>
          </div>
          </motion.div>

          

          
          <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:1.3}} onClick={() => setActiveSection('Settings')} style={{backgroundColor:BackgroundOption('Settings')}} className='Item'>
          <div>
          <FontAwesomeIcon  size={window.innerWidth < 1000 ? '3x': '1x'} color={ColourOption('Settings')} icon={faGear} /><h1 style={{color:ColourOption('Settings')}}>Settings</h1>
          </div>
          </motion.div>

          
        </div>
            
      </div>

      <div className='Main-Terminal'>
        {activeSection === 'Invoice' && (
          <InvoiceGenerator/>
        )
        
        }

        {activeSection === 'Customers' && (
          <Customers/>
        )
        
        }

        {activeSection === 'Settings' && (
          <Settings/>
        )
        
        }

        {activeSection === 'History' && (
                  <History/>
                )
                
        }

        {activeSection === 'Invoices' && (
                          <Invoices/>
              )
                        
        }

        {activeSection === 'Drafts' && (
                          <Drafts/>
                        )
                        
        }

{activeSection === 'Pending' && (
                          <Approval/>
                        )
                        
        }
        
        

      </div>
      

    </div>
    </>

  );

}

export default Console;
