import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './Components/LoginComponents/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Console from './Components/ConsoleComponents/Console';
import { Primary, Secondary, Tertiary } from './Components/Constants/Colours';
import BlurredLogo from './Components/GlobalComponents/BlurredLogo';
import BlurredInvLogo from './Components/GlobalComponents/BlurredInvLogo';
import eventEmitter from './Components/GlobalComponents/EventEmitter';


function App() {
  const [auth,setAuth] = useState<boolean>(false)

  const SignOut = () => {
    setAuth(false)
    localStorage.setItem('isLoggedIn', 'false')
  }

  useEffect(() => {
        
    
    eventEmitter.on('signOut', SignOut);  // Listen for the event

  return () => {
    eventEmitter.off('signOut', SignOut);  // Cleanup when the component unmounts
  };
}, []);

  return (
    <div className="App">
      <div style={{borderColor:Secondary}} className='Background-Flame'><BlurredInvLogo size={8}/></div>
      <div className='Background-Logo'><BlurredLogo scale={4} colour={Secondary}/></div>
      {auth ? 
      <Console/>
      : 
      <Login setAuth={setAuth}/> 
      }
    </div>
  );
}

export default App;
