import React from 'react';
import './Invoice.css';
import PDFViewer from 'pdf-viewer-reactjs';


interface InvoiceViewerProps {
    pdfUrl: string;
}

const InvoiceViewer: React.FC<InvoiceViewerProps> = ({ pdfUrl }) => {
    return (
        <iframe
        src={pdfUrl}
        style={{ width: '100%', height: '90%' }}
        title="PDF Viewer"
      >
        This browser does not support PDFs. Please download the PDF to view it: <a href={pdfUrl}>Download PDF</a>
      </iframe>
      
    );
};

export default InvoiceViewer;