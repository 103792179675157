import React, { useEffect, useState } from 'react';
import './Customer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { motion } from 'framer-motion';
import PocketBase from 'pocketbase';
import { faArrowLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InvoiceGenerator from './Invoice';

interface SearchResult {
    category: string;
    customer: any;
    matchedText: string;
    date?: string;
    address?: any;
    matchType?: 'address' | 'postcode';
}

interface CustomersProps {
    identifier?: string;
    closeView?: () => void;
    initLoading?: boolean;
}

const Customers = ({ identifier, closeView, initLoading }: CustomersProps) => {
    const [customers, setCustomers] = useState<any[]>([]);
    const [allCustomers, setAllCustomers] = useState<any[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [customerSelected, setCustomerSelected] = useState(false);
    const [customerAddresses, setCustomerAddresses] = useState<any[]>([]);
    const [customerInvoices, setCustomerInvoices] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<{ [key: string]: SearchResult[] }>({});
    const [viewingInvoice, setViewingInvoice] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(initLoading || false);
    const custidentifier = identifier || ''
    const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase');

    const GetCustomers = async () => {
        try {
            const cust = await pb.collection('Customers').getFullList();
            cust.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
            setCustomers(cust);
            setAllCustomers(cust);
        } catch {
            setCustomers([]);
        }
    };

    const formatDate = (dateString: any) => {
        if (!dateString) {
            return undefined;
        }

        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const isDateString = (str: string) => {
        const datePatterns = [
            /^\d{1,2}[-/]\d{1,2}[-/]\d{2,4}$/,  // DD/MM/YY, MM/DD/YY
            /^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/,     // YYYY-MM-DD
            /^\d{1,2}\s+(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*\s+\d{2,4}$/i  // 1 Jan 2024
        ];
        return datePatterns.some(pattern => pattern.test(str.trim()));
    };

    const matchesDateQuery = (formattedDate: string, searchTerm: string): boolean => {
        const normalizedSearch = searchTerm.toLowerCase().trim();
        const normalizedDate = formattedDate.toLowerCase();

        const monthMappings: { [key: string]: string[] } = {
            'january': ['jan', 'january'],
            'february': ['feb', 'february'],
            'march': ['mar', 'march'],
            'april': ['apr', 'april'],
            'may': ['may'],
            'june': ['jun', 'june'],
            'july': ['jul', 'july'],
            'august': ['aug', 'august'],
            'september': ['sep', 'sept', 'september'],
            'october': ['oct', 'october'],
            'november': ['nov', 'november'],
            'december': ['dec', 'december']
        };

        if (isDateString(searchTerm)) {
            return new Date(formattedDate).toLocaleDateString().toLowerCase().includes(normalizedSearch);
        }

        for (const [_, variations] of Object.entries(monthMappings)) {
            if (variations.includes(normalizedSearch) && normalizedDate.includes(variations[0])) {
                return true;
            }
        }

        return normalizedDate.includes(normalizedSearch);
    };

    const handleSearch = async (term: string) => {
        setSearchTerm(term);
        const searchTermTrimmed = term.trim().toLowerCase();
        if (searchTermTrimmed === '') {
            setCustomers(allCustomers);
            setSearchResults({});
            return;
        }

        try {
            const results: { [key: string]: SearchResult[] } = {
                'By Name': [],
                'By Location': [],
                'By Email': [],
                'By Invoice Date': [],
                'By Invoice Description': []
            };

            // Use Sets to track unique matches
            const nameSet = new Set<string>();
            const emailSet = new Set<string>();
            const locationSet = new Set<string>();
            const invoiceDateSet = new Set<string>();
            const invoiceDescSet = new Set<string>();

            await Promise.all(
                allCustomers.map(async (customer) => {
                    // By Name
                    if (customer.Name && customer.Name.toLowerCase().includes(searchTermTrimmed)) {
                        if (!nameSet.has(customer.id)) {
                            results['By Name'].push({
                                category: 'By Name',
                                customer: customer,
                                matchedText: customer.Name
                            });
                            nameSet.add(customer.id);
                        }
                    }

                    // By Email
                    if (customer.Email && customer.Email.toLowerCase().includes(searchTermTrimmed)) {
                        if (!emailSet.has(customer.id)) {
                            results['By Email'].push({
                                category: 'By Email',
                                customer: customer,
                                matchedText: customer.Email
                            });
                            emailSet.add(customer.id);
                        }
                    }

                    // Check invoices
                    for (const invoiceId of customer.Invoices) {
                        try {
                            const invoice = await pb.collection('Invoices').getOne(invoiceId);
                            const address = await pb.collection('Addresses').getOne(invoice.Address);

                            // By Location (Address)
                            if (address.Address && address.Address.toLowerCase().includes(searchTermTrimmed)) {
                                const locKey = `${customer.id}-${address.id}-address`;
                                if (!locationSet.has(locKey)) {
                                    results['By Location'].push({
                                        category: 'By Location',
                                        customer: customer,
                                        matchedText: address.Address,
                                        date: invoice.Date,
                                        address: address,
                                        matchType: 'address'
                                    });
                                    locationSet.add(locKey);
                                }
                            }

                            // By Location (Postcode)
                            if (address.Postcode && address.Postcode.toLowerCase().includes(searchTermTrimmed)) {
                                const locKey = `${customer.id}-${address.id}-postcode`;
                                if (!locationSet.has(locKey)) {
                                    results['By Location'].push({
                                        category: 'By Location',
                                        customer: customer,
                                        matchedText: address.Postcode,
                                        date: invoice.Date,
                                        address: address,
                                        matchType: 'postcode'
                                    });
                                    locationSet.add(locKey);
                                }
                            }

                            // By Invoice Date
                            const formattedDate = formatDate(invoice.Date);
                            if (formattedDate && matchesDateQuery(formattedDate, term)) {
                                const dateKey = `${customer.id}-${invoice.Date}`;
                                if (!invoiceDateSet.has(dateKey)) {
                                    results['By Invoice Date'].push({
                                        category: 'By Invoice Date',
                                        customer: customer,
                                        matchedText: formattedDate,
                                        date: invoice.Date
                                    });
                                    invoiceDateSet.add(dateKey);
                                }
                            }

                            // By Invoice Description
                            if (invoice.Description && invoice.Description.trim() !== '' && invoice.Description.toLowerCase().includes(searchTermTrimmed)) {
                                const descKey = `${customer.id}-${invoice.id}`;
                                if (!invoiceDescSet.has(descKey)) {
                                    results['By Invoice Description'].push({
                                        category: 'By Invoice Description',
                                        customer: customer,
                                        matchedText: invoice.Description,
                                        date: invoice.Date
                                    });
                                    invoiceDescSet.add(descKey);
                                }
                            }
                        } catch {
                            // Handle invoice/address fetch error silently
                        }
                    }
                })
            );

            // Remove duplicates within each category (handled by Sets)

            // Update the combined results
            const allMatchedCustomers = Array.from(new Set([
                ...results['By Name'].map(r => r.customer.id),
                ...results['By Email'].map(r => r.customer.id),
                ...results['By Location'].map(r => r.customer.id),
                ...results['By Invoice Date'].map(r => r.customer.id),
                ...results['By Invoice Description'].map(r => r.customer.id)
            ])).map(id => allCustomers.find(c => c.id === id)).filter(Boolean);

            setSearchResults(results);
            setCustomers(allMatchedCustomers as any[]);
        } catch (error) {
            console.error('Search error:', error);
            setCustomers([]);
            setSearchResults({});
        }
    };

    

    const GetMostVisited = async (customer: any) => {
        try {
            const visited = [];
            for (const invoice of customer.Invoices) {
                const inv = await pb.collection('Invoices').getOne(invoice);
                const addy = await pb.collection('Addresses').getOne(inv.Address);
                visited.push(addy);
            }
            const findMostCommonAddress = (visited: any[]) => {
                const addressCountMap: { [key: string]: number } = {};
                let mostCommonAddress = '';

                visited.forEach((address: any) => {
                    const addr = address.Address;
                    if (addressCountMap[addr]) {
                        addressCountMap[addr]++;
                    } else {
                        addressCountMap[addr] = 1;
                    }
                });

                mostCommonAddress = Object.keys(addressCountMap).reduce((a, b) =>
                    addressCountMap[a] > addressCountMap[b] ? a : b
                );

                return mostCommonAddress;
            };

            return findMostCommonAddress(visited);
        } catch {
            return 'No Address Found';
        }
    };

    const SelectCustomer = (customer: any) => {
        setCustomerAddresses([]);
        setCustomerInvoices([]);
        setSelectedCustomer(customer);
        setCustomerSelected(true);

        // Fetch addresses
        Promise.all(customer.Addresses.map((addy: string) => pb.collection('Addresses').getOne(addy)))
            .then((addresses) => {
                setCustomerAddresses(addresses);
            });

        // Fetch invoices
        Promise.all(customer.Invoices.map((inv: string) => pb.collection('Invoices').getOne(inv)))
            .then((invoices) => {
                setCustomerInvoices(invoices);
            });
    };

    useEffect(() => {
        const initializeWithCustomer = async () => {
            if (custidentifier) {
                setIsLoading(true);
                try {
                    const customer = await pb.collection('Customers').getOne(custidentifier);
                    await GetCustomers(); // Load all customers first
                    SelectCustomer(customer); // Then select the specific customer
                } catch (error) {
                    console.error('Error loading customer:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        initializeWithCustomer();
    }, [identifier]);

    useEffect(() => {
        GetCustomers();
    }, []);

    const handleClose = () => {
        if (closeView) {
            closeView();
        }
    };

    const highlightMatch = (text: string, searchTerm: string) => {
        if (!text || !searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text.split(regex);

        return (
            <span>
                {parts.map((part, i) =>
                    regex.test(part) ?
                        <span key={i} className="Highlighted-Text">{part}</span> :
                        part
                )}
            </span>
        );
    };

    if (viewingInvoice) {
        return (
            <InvoiceGenerator initLoading={true} identifier={viewingInvoice} closeView={() => setViewingInvoice('')}/> 
        )
    }

    return (
        <div className='Customers'>
            {closeView ? 
               
                <motion.div layout className='Customers-Header'>
                     <motion.section whileHover={{scale:1.1}} whileTap={{scale:0.8}}  onClick={closeView} className='Back'>
                        <FontAwesomeIcon color='#071224' size='3x' icon={faArrowLeft}/>
                    </motion.section>
                <motion.h1 className='Customer-Title'>Customers</motion.h1>
                </motion.div>


                :
                <div className='Customers-Header'>
                <h1 className='Customer-Title'>Customers</h1>
                <div>
                    <input onChange={(e) => handleSearch(e.target.value)} type='text' />
                    <FontAwesomeIcon color='#0f57fb' icon={faMagnifyingGlass} size='1x' />
                </div>
            </div>
                
            }
            
            
            <div className='Customer-Table'>
                <div className='Customer-List'>
                    <div className='List'>
                        {searchTerm ? (
                            Object.entries(searchResults)
                                .sort(([a], [b]) => {
                                    const order = [
                                        'By Name',
                                        'By Location',
                                        'By Email',
                                        'By Invoice Date',
                                        'By Invoice Description'
                                    ];
                                    return order.indexOf(a) - order.indexOf(b);
                                })
                                .map(([category, results]) => (
                                    results.length > 0 && (
                                        <div key={category} className='Search-Category'>
                                            <h2 className='Category-Title'>{category}</h2>
                                            {results.map((result) => (
                                                <motion.div
                                                    layout
                                                    key={`${result.customer.id}-${category}-${result.matchedText}`}
                                                    className='Customer-Item'
                                                    whileHover={{ scale: 0.95 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    onClick={() => SelectCustomer(result.customer)}
                                                >
                                                    <div className='Customer-Item-Containers'>
                                                        <h1>{result.customer.Name}</h1>
                                                        <p className='Matched-Text'>
                                                            {category === 'By Invoice Description' && formatDate(result.date) + ' | '}
                                                            {category === 'By Location' &&
                                                                `${result.address.Address || ''}, ${result.address.Postcode || ''} | `}
                                                            Matched: {highlightMatch(result.matchedText, searchTerm)}
                                                           
                                                        </p>
                                                    </div>
                                                </motion.div>
                                            ))}
                                        </div>
                                    )
                                ))
                        ) : (
                            customers.map((customer: any) => (
                                <motion.div
                                    layout
                                    key={customer.id}
                                    className='Customer-Item'
                                    whileHover={{ scale: 0.95 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => SelectCustomer(customer)}
                                >
                                    <div className='Customer-Item-Containers'>
                                        <h1>{customer.Name}</h1>
                                    </div>
                                </motion.div>
                            ))
                        )}
                    </div>

                    <div className='Customer-View'>
                        <motion.div animate={{ y: customerSelected ? '0%' : '40%', scale: customerSelected ? 1 : 2 }} className='Icon-Position'>
                            <motion.div animate={{ borderColor: customerSelected ? '#071224' : '#07122400' }} className='Customer-Icon'>
                                <FontAwesomeIcon icon={faUser} size='5x' />
                            </motion.div>
                            {!customerSelected && (<h1>No Customer Selected</h1>)}
                        </motion.div>
                        {customerSelected && (
                            <>
                                <div className='Detail-Row'>
                                    <h2>Name</h2>
                                    <h1>{selectedCustomer?.Name}</h1>
                                </div>
                                <div className='Detail-Row'>
                                    <h2>Email</h2>
                                    {selectedCustomer.Email ?
                                        <h1>{selectedCustomer.Email}</h1>
                                        :
                                        <input placeholder='Enter an email' />
                                    }
                                </div>
                                <div className='Detail-Row'>
                                    <h2>Phone</h2>
                                    {selectedCustomer.Phone ?
                                        <h1>{selectedCustomer.Phone}</h1>
                                        :
                                        <input placeholder='Enter a phone number' />
                                    }
                                </div>
                                {customerAddresses.length > 0 && (
                                    <div className='Detail-Row'>
                                        <h2>Addresses</h2>
                                        {customerAddresses.map((address: any) => (
                                            <div className='Detail-Row' key={address.id}>
                                                <h1>{address.Address}</h1>
                                                {customerInvoices
                                                    .filter((invoice: any) => invoice.Address === address.id)
                                                    .map((invoice: any) => (
                                                        <motion.div  
                                                        onClick={() => setViewingInvoice(invoice.id)}
                                                        whileHover={{ scale: 0.9,padding:'2.5px',backgroundColor:'#071224',color:'white',borderRadius:'3px',cursor:'pointer' }} whileTap={{ scale: 0.95 }} 
                                                        className='Detail-Row' key={invoice.id}>
                                                            <h3>Invoice {invoice.Number} | {formatDate(invoice.Date)}{invoice.Total !== 0 ? ' | Charged £' + invoice.Total : ''}</h3>
                                                        </motion.div>
                                                    ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Customers;


