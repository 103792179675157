import React, { useEffect, useState } from 'react';
import './Approval.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck,faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { Primary } from '../Constants/Colours';
import PocketBase from 'pocketbase';
import InvoiceGenerator from './Invoice';
import Customers from './Customers';

const Approval = () => {
    const [reviews,setReviews] = useState<any>([]);
    const [viewingInvoice, setViewingInvoice] = useState<any>(null);
    const [mostVisitedAddresses, setMostVisitedAddresses] = useState<{ [key: string]: string }>({});
    const [viewingCustomer, setViewingCustomer] = useState<any>(null);

    const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase');
    pb.autoCancellation(false)


    const GetHistory = async () => {
        const count = await pb.collection('Reviews').getList(1,50,{filter:'Submitted = true && Approved = false',sort:'-created'})
        const revs = count.items
        const fin : any = []
        for (const review of revs) {
            const customer = await pb.collection('Customers').getOne(review.Customer)
            const revdict = {
                'Customer':customer,
                'Review':review
            }
            fin.push(revdict)
        }
        setReviews(fin);

    }

    const Moderate = async (review : any,decision : any) => {
        if (decision) {
        const revidict = {
            'Approved':true
        }
        const revi = await pb.collection('Reviews').update(review.id,revidict)
        setReviews(reviews.filter((r:any) => r.Review.id !== review.id));

            
        } else {
            const revi = await pb.collection('Reviews').delete(review.id)
            setReviews(reviews.filter((r:any) => r.Review.id !== review.id));

        }
    }


    const formatDate = (dateString: any) => {
        if (!dateString) {
            return undefined;
        }
        console.log(dateString)
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const handleViewInvoice = (id:any,event:any) => {
        if (event.target === event.currentTarget) {
            setViewingInvoice(id);
        }
    }


    const handleCustomerClick = (customerId: any, event: any) => {
        event.stopPropagation();
        setViewingCustomer(customerId);
    }

    const Stars = (starAmounts: any) => {
        const starAmount = starAmounts.starAmount
        return (
            <div style={{ display: "inline" }}>
                {starAmount >= 1 && <FontAwesomeIcon size="lg" color='gold' icon={faStar} />}
                {starAmount >= 2 && <FontAwesomeIcon size="lg" color='gold' icon={faStar} />}
                {starAmount >= 3 && <FontAwesomeIcon size="lg" color='gold' icon={faStar} />}
                {starAmount >= 4 && <FontAwesomeIcon size="lg" color='gold' icon={faStar} />}
                {starAmount >= 5 && <FontAwesomeIcon size="lg" color='gold' icon={faStar} />}
            </div>
        );
    }

    useEffect(() => {
        GetHistory()
    }, []);

    return (
        <div className='Review'>
            <div className='Review-Header'>
                <h1 className='Review-Title'>Pending Approval</h1>
            </div>

            <div className='Review-Body'>
                <div className='Review-List'>
                    {reviews.length == 0 && <h3 style={{alignSelf:'center'}}>No Reviews Pending Approval</h3>}
                    {reviews.map((reviewItem: any) => (
                        <motion.div 
                                         layout className='Review-Item-Container'>
                            <div>
                                <div className='Name-Stars'>
                                <h2>{reviewItem['Customer']?.Name || ""} on {formatDate(reviewItem['Review']?.Submission_Date)}</h2>
                              
                                <Stars starAmount={reviewItem['Review']?.Stars}/>
                                </div>
                        
                                <h2>{reviewItem['Review']?.Review || ""}</h2>


                            </div>

                            <section>
                                <motion.div title='Approve' whileTap={{scale:0.9}} whileHover={{scale:1.1}} onClick={() => Moderate(reviewItem['Review'],true)}>
                                <FontAwesomeIcon color={Primary}  size='3x' icon={faCircleCheck} />

                                </motion.div>
                                <motion.div title='Decline' whileTap={{scale:0.9}} whileHover={{scale:1.1}} onClick={() => Moderate(reviewItem['Review'],false)}>
                                <FontAwesomeIcon color='red' size='3x' icon={faCircleXmark} />
                                </motion.div>
                            </section>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Approval;
