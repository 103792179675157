import React, { useEffect, useState } from 'react';
import './History.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { motion } from 'framer-motion';
import PocketBase from 'pocketbase';
import InvoiceGenerator from './Invoice';
import Customers from './Customers';

const History = () => {
    const [defaultFrom, setDefaultFrom] = useState<any>('');
    const [defaultNotes, setDefaultNotes] = useState<any>('');
    const [defaultPT, setDefaultPT] = useState<any>('');
    const [invoiceNumber, setInvoiceNumber] = useState<any>(0);
    const [sentInvoices,setSentInvoices] = useState<any>([]);
    const [viewingInvoice, setViewingInvoice] = useState<any>(null);
    const [mostVisitedAddresses, setMostVisitedAddresses] = useState<{ [key: string]: string }>({});
    const [viewingCustomer, setViewingCustomer] = useState<any>(null);

    const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase');
    pb.autoCancellation(false)


    const GetHistory = async () => {
        const count = await pb.collection('History').getFullList()
        const invs = []
        for (const invoice of count) {
            const inv = await pb.collection('Invoices').getOne(invoice.Invoice)
            const addy = await pb.collection('Customers').getOne(invoice.Customer)
            const invdata = {
                'Date': invoice.Sent,
                'Invoice': inv,
                'Customer': addy,
                'Medium': invoice.Medium
            } 
            console.log(invdata)
            invs.push(invdata)
        }
        invs.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
        setSentInvoices(invs);

    }


    const UpdateFrom = async (value: any) => {
        setDefaultFrom(value)
        const count = await pb.collection('Users').getFullList()
        pb.collection('Users').update(count[0].id, {Default_From: value})
    }

    const UpdateNotes = async (value:any) => {
        setDefaultNotes(value)
        const count = await pb.collection('Users').getFullList()
        pb.collection('Users').update(count[0].id, {Default_Notes: value})
    }

    const UpdatePT = async (value:any) => {
        setDefaultPT(value)
        const count = await pb.collection('Users').getFullList()
        pb.collection('Users').update(count[0].id, {Default_PT: value})
    }

    const UpdateInvoiceNumber = async (value:any) => {
        setInvoiceNumber(value)
        const count = await pb.collection('Users').getFullList()
        pb.collection('Users').update(count[0].id, {Invoice_Number: value})
    }

    const formatDate = (dateString: any) => {
        if (!dateString) {
            return undefined;
        }
        console.log(dateString)
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric',hour: '2-digit', minute: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const handleViewInvoice = (id:any,event:any) => {
        if (event.target === event.currentTarget) {
            setViewingInvoice(id);
        }
    }

    const handleCustomerClick = (customerId: any, event: any) => {
        event.stopPropagation();
        setViewingCustomer(customerId);
    }

    useEffect(() => {
        GetHistory()
    }, []);

    if (viewingInvoice) {
        return (
            <InvoiceGenerator initLoading={true} identifier={viewingInvoice} closeView={() => setViewingInvoice('')}/>
        )
    }

    if (viewingCustomer) {
        return (
            <Customers initLoading={true} identifier={viewingCustomer} closeView={() => setViewingCustomer('')}/>
        )
    }

    return (
        <div className='History'>
            <div
            className='History-Header'
                >
                    <h1 className='History-Title'>Sent Invoices</h1>
                </div>

            <div className='History-Body'>
            <div className='History-List'>
                        {sentInvoices.map((historyItem: any) => (
                            
                                <motion.div title='Open Invoice' whileTap={{ scale: 0.9 }}
                                onClick={(e) => handleViewInvoice(historyItem.Invoice.id,e)} layout className='Customer-Item-Container'>
                                    <h1>Sent to <motion.span title='View Customer' onClick={(e) => setViewingCustomer(historyItem.Customer.id)}
                                    whileHover={{ paddingTop:'2.5px',paddingBottom:'2.5px',backgroundColor:'white',color:'#071224',borderRadius:'3px',cursor:'pointer' }} whileTap={{ scale: 0.8 }} 
                                    >{historyItem.Customer.Name}</motion.span><br/><span className='Sub-Span'>Invoice sent to {historyItem.Customer.Name} at the {historyItem.Medium == 'Email' ? 'email address':'phone number'} {historyItem.Medium == 'Email' ? historyItem.Customer.Email:historyItem.Customer.Phone}</span></h1>
                                    
                                    <div>
                                    <h3>Invoice {historyItem.Invoice.Number}{historyItem.Invoice.Total !== 0 ? ' | Charged £' + historyItem.Invoice.Total : ''}</h3>
                                    <h3>{formatDate(historyItem.Date)}</h3>
                                    </div>
                                </motion.div>
                         
                        ))}
                    </div>
            

            </div>
            
        </div>
    );
};

export default History;
