import React, { useEffect, useState } from 'react';
import './Settings.css';
import { motion } from 'framer-motion';
import PocketBase from 'pocketbase';
import eventEmitter from '../GlobalComponents/EventEmitter';

const Settings = () => {
    const [defaultFrom, setDefaultFrom] = useState<any>('');
    const [defaultNotes, setDefaultNotes] = useState<any>('');
    const [defaultPT, setDefaultPT] = useState<any>('');
    const [invoiceNumber, setInvoiceNumber] = useState<any>(0);

    const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase');
    pb.autoCancellation(false);

    const GetUserSettings = async () => {
        const count = await pb.collection('Users').getFullList();
        setDefaultFrom(count[0].Default_From);
        setDefaultNotes(count[0].Default_Notes);
        setDefaultPT(count[0].Default_PT);
        setInvoiceNumber(count[0].Invoice_Number);
    };

    const UpdateSetting = async (field: string, value: any) => {
        const count = await pb.collection('Users').getFullList();
        await pb.collection('Users').update(count[0].id, { [field]: value });
    };

    const SignOut = () => {
        eventEmitter.emit('signOut');
    };

    useEffect(() => {
        GetUserSettings();
    }, []);

    return (
        <div className='Settings'>
            <div className='Settings-Header'>
                <h1 className='Settings-Title'>Settings</h1>
            </div>
            <div className='Settings-Body'>
                
                <div>
                    <h3>Invoice Number</h3>
                    <input type='number' value={invoiceNumber} onChange={(e) => UpdateSetting('Invoice_Number', e.target.value)} />
                    <h3>Default From</h3>
                    <textarea defaultValue={defaultFrom} onChange={(e) => UpdateSetting('Default_From', e.target.value)} />
                </div>
                <div>
                <h3 style={{whiteSpace:'nowrap'}}>Default Payment Terms</h3>
                <input defaultValue={defaultPT} onChange={(e) => UpdateSetting('Default_PT', e.target.value)} />
                    <h3>Default Notes</h3>
                    <textarea defaultValue={defaultNotes} onChange={(e) => UpdateSetting('Default_Notes', e.target.value)} />
                </div>
            </div>
            <motion.button className='Sign-Out-Button' onClick={SignOut} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                Sign Out
            </motion.button>
        </div>
    );
};

export default Settings;
