import React, { useEffect, useState } from 'react';
import './Drafts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faEdit, faTrash,faXmark } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import PocketBase from 'pocketbase';
import InvoiceViewer from './InvoiceViewer';
import eventEmitter from '../GlobalComponents/EventEmitter';

interface Draft {
    id: string;
    Number: number;
    Date: string;
    Total: number;
    Customer: any;
}

const Drafts = () => {
    const [drafts, setDrafts] = useState<any>([]);
    const [selectedDraft, setSelectedDraft] = useState<string | null>(null);
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase');

    const fetchDrafts = async () => {
        try {
            const data = await pb.collection('Invoice_Drafts').getFullList();
            data.sort((a, b) => b.Number - a.Number);
            setDrafts(data);
        } catch (error) {
            console.error('Error fetching drafts:', error);
            setDrafts([]);
        }
    };



    const handleDeleteDraft = async (id: string) => {
        try {
            await pb.collection('Invoice_Drafts').delete(id);
            setDrafts(drafts.filter((draft : any) => draft.id !== id));
        } catch (error) {
            console.error('Error deleting draft:', error);
        }
    };

    useEffect(() => {
        fetchDrafts();
    }, []);

    return (
        <div className='Drafts'>
            <div className='Drafts-Header'>
                <h1 className='Drafts-Title'>Drafts</h1>
                <div className='Drafts-Search'>
                    {/* You can implement a search bar here similar to History */}
                </div>
            </div>
            <div className='Drafts-List'>
                {drafts.map((draft : any) => (
                    <motion.div
                        layout
                        key={draft.id}
                        className='Draft-Item'
                        whileHover={{ scale: 0.95 }}
                        whileTap={{ scale: 0.9 }}
                     
                    >
                        <div className='Draft-Details'>
                            <h2>Draft {draft.Draft_Number}</h2>
                            <p>Invoice Number: {draft.Number}</p>
                        </div>
                        <div className='Draft-Actions'>
                            
                            <FontAwesomeIcon icon={faTrash} size='4x' color='red' title='Delete Draft' onClick={(e) => { e.stopPropagation(); handleDeleteDraft(draft.id); }} />
                        </div>
                    </motion.div>
                ))}
            </div>


        </div>
    );
};

export default Drafts;
