import React, {
  useRef,
  useState,
  useEffect,
  RefObject,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import PocketBase from 'pocketbase';
import { motion } from 'framer-motion';

interface Address {
  id: string;
  Address: string;
}

interface Customer {
  id: string;
  Name: string;
  Addresses: Address[];  // Populated after fetching
}

interface Coordinates {
  x: number;
  y: number;
}

interface CustomerTextPredictionProps {
  changed?: (value: string) => void; // optional
  blurred?: () => void;    // optional
}

function CustomerTextPrediction({ changed, blurred }: CustomerTextPredictionProps) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const mirrorRef = useRef<HTMLDivElement | null>(null);

  // Data and text state
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [textInputted, setTextInputted] = useState<string>('');
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(undefined);
  const [coords, setCoords] = useState<Coordinates>({ x: 0, y: 0 });

  // Used to store our matching suggestions
  const [matchingCustomers, setMatchingCustomers] = useState<Customer[]>([]);
  const [matchingAddresses, setMatchingAddresses] = useState<Address[]>([]);

  // Initialize PocketBase client
  const pb = useMemo(() => new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase'), []);
  useEffect(() => {
    pb.autoCancellation(false);
  }, [pb]);

  // Fetch data once (customers + addresses in one go)
  useEffect(() => {
    (async () => {
      try {
        const [customerList, addressList] = await Promise.all([
          pb.collection('Customers').getFullList<Customer>(),
          pb.collection('Addresses').getFullList<Address>(),
        ]);

        // Put addresses into a map for quick lookup
        const addressMap = new Map<string, Address>();
        addressList.forEach((addr) => {
          addressMap.set(addr.id, addr);
        });

        // Attach addresses to each customer
        customerList.forEach((cust) => {
          // If cust.Addresses is an array of ID strings, map them to Address objects
          cust.Addresses = (cust.Addresses as unknown as string[]).map(
            (addrId: string) => addressMap.get(addrId)!
          );
        });

        setCustomers(customerList);
      } catch {
        console.log('Failed to fetch Customers or Addresses');
      }
    })();

    // Sync mirror styles on mount
    updateMirrorStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleAnyAncestorScroll = () => {
      // Clear suggestions if any scrolling happens
      setMatchingCustomers([]);
      setMatchingAddresses([]);
    };

    // Listen at document level on capturing phase
    document.addEventListener('scroll', handleAnyAncestorScroll, {
      capture: true, // <--- Key for capturing phase
      passive: true,
    });

    return () => {
      document.removeEventListener('scroll', handleAnyAncestorScroll, {
        capture: true,
      } as EventListenerOptions);
    };
  }, []);

  // Whenever text changes, if there's a selected customer, look up addresses; otherwise, find customers
  useEffect(() => {
    if (selectedCustomer) {
      const typedString = textInputted.slice(selectedCustomer.Name.length).trim();
      setMatchingAddresses(findMatchingAddresses(typedString, selectedCustomer));
    } else {
      setMatchingCustomers(findMatchingCustomers(textInputted, customers));
    }
  }, [textInputted, customers, selectedCustomer]);

  // If user deletes or modifies the customer’s name so it no longer matches, deselect them
  useEffect(() => {
    if (selectedCustomer && !textInputted.includes(selectedCustomer.Name)) {
      setSelectedCustomer(undefined);
    }
  }, [textInputted, selectedCustomer]);

  // --- Memoized Functions ---

  // Find matching customers (returns array; we set state in an effect).
  const findMatchingCustomers = useCallback((input: string, all: Customer[]) => {
    if (!input) return [];

    const search = input.toLowerCase();
    return all
      .filter((c) => c.Name.toLowerCase().includes(search))
      .sort((a, b) => {
        // "closeness" measure: exact -> startsWith -> includes -> fallback
        const closeness = (name: string) => {
          const n = name.toLowerCase();
          if (n === search) return 0;
          if (n.startsWith(search)) return 1;
          if (n.includes(search)) return 2;
          return 3;
        };
        return closeness(a.Name) - closeness(b.Name);
      });
  }, []);

  // Find matching addresses
  const findMatchingAddresses = useCallback((typedString: string, cust: Customer) => {
    if (!typedString) return [];
    const words = typedString.toLowerCase().split(/\s+/).filter(Boolean);

    return cust.Addresses
      .filter((addr) => {
        const candidate = addr.Address.toLowerCase();
        return words.some((word) => candidate.includes(word));
      })
      .sort((a, b) => {
        const closeness = (address: string) => {
          const lower = address.toLowerCase();
          // all words included => most relevant
          if (words.every((w) => lower.includes(w))) return 0;
          // partial matches => fallback
          if (words.some((w) => lower.startsWith(w))) return 1;
          if (words.some((w) => lower.includes(w))) return 2;
          return 3;
        };
        return closeness(a.Address) - closeness(b.Address);
      });
  }, []);

  // Mirror styling on mount
  const updateMirrorStyles = useCallback(() => {
    if (!textareaRef.current || !mirrorRef.current) return;

    const style = window.getComputedStyle(textareaRef.current);
    const mirror = mirrorRef.current;

    mirror.style.whiteSpace = 'pre-wrap';
    mirror.style.position = 'absolute';
    mirror.style.visibility = 'hidden';
    mirror.style.top = '0';
    mirror.style.left = '-9999px'; // keep it off-screen

    // Copy over text/box styles
    mirror.style.fontSize = style.fontSize;
    mirror.style.fontFamily = style.fontFamily;
    mirror.style.fontWeight = style.fontWeight;
    mirror.style.letterSpacing = style.letterSpacing;
    mirror.style.lineHeight = style.lineHeight;
    mirror.style.textTransform = style.textTransform;
    mirror.style.width = style.width;
    mirror.style.padding = style.padding;
    mirror.style.border = style.border;
    mirror.style.boxSizing = style.boxSizing;
  }, []);

  // Caret measurement
  const measureCaret = useCallback(() => {
    if (!textareaRef.current || !mirrorRef.current) return;

    const textarea = textareaRef.current;
    const mirror = mirrorRef.current;
    const { selectionStart } = textarea;
    const textValue = textarea.value.substring(0, selectionStart || 0);

    mirror.textContent = textValue || '';

    // create a <span> to mark the caret location
    const caretSpan = document.createElement('span');
    caretSpan.textContent = textValue ? textValue.slice(-1) : '\u00A0';
    mirror.appendChild(caretSpan);

    const mirrorRect = mirror.getBoundingClientRect();
    const caretSpanRect = caretSpan.getBoundingClientRect();
    caretSpan.textContent = '';

    // measure the textarea
    const textareaRect = textarea.getBoundingClientRect();

    let x =
      textareaRect.left + (caretSpanRect.left - mirrorRect.left) - textarea.scrollLeft;
    let y =
      textareaRect.top + (caretSpanRect.top - mirrorRect.top) - textarea.scrollTop;

    // account for page scrolling
    const pageX = window.scrollX || window.pageXOffset;
    const pageY = window.scrollY || window.pageYOffset;
    x += pageX;
    y += pageY;

    setCoords({ x, y });
  }, []);

  // Render bubble in a portal
  function BubblePortal({ x, y }: Coordinates) {
    const hasOptions = matchingCustomers.length > 0 || matchingAddresses.length > 0;

    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: y,
          left: x,
          width: 'auto',
          height: '5vh',
          borderRadius: '14px',
          borderBottomLeftRadius:"0px",
          backgroundColor: 'white',
          border: '1px solid black',
          display: hasOptions ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexDirection: 'row',
          transform: 'translate(5%, -110%)',
          zIndex: 9999, // Make sure it's on top
        }}
      >
        {selectedCustomer
          ? matchingAddresses.slice(0, 3).map((address) => (
              <motion.div
                key={address.id}
                style={{
                  fontFamily: 'Poppins Light',
                  fontSize: '12px',
                  padding: '2.5px 10px',
                  margin: '0px 10px',
                  borderRadius: '3px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // Replace the last typed "word" with the chosen address
                  const lastSpaceIndex = textInputted.lastIndexOf(' ');
                  const newText =
                    lastSpaceIndex === -1
                      ? address.Address
                      : textInputted.slice(0, lastSpaceIndex + 1) + address.Address;

                  setTextInputted(newText);
                  if (changed){
                  changed(newText)}
                  setMatchingAddresses([]);
                  textareaRef.current?.focus();
                }}
                whileHover={{ backgroundColor: 'navy', color: 'white', scale: 1.05 }}
              >
                {address.Address}
              </motion.div>
            ))
          : matchingCustomers.slice(0, 3).map((cust) => (
              <motion.div
                key={cust.id}
                style={{
                  fontFamily: 'Poppins Light',
                  fontSize: '12px',
                  padding: '2.5px 10px',
                  margin: '0px 10px',
                  borderRadius: '3px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setTextInputted(cust.Name);
                  setSelectedCustomer(cust);
                  setMatchingCustomers([]);
                  if (changed) {
                    changed(cust.Name)
                  }
                  textareaRef.current?.focus();
                }}
                whileHover={{ backgroundColor: 'navy', color: 'white', scale: 1.05 }}
              >
                {cust.Name}
              </motion.div>
            ))}
      </div>,
      document.body
    );
  }

  return (
    <div style={{ margin: '0px', width: '100%' }}>
      <textarea
        ref={textareaRef}
        rows={1}
        style={{
          backgroundColor: '#e0e3ec',
          fontFamily: 'Poppins Light',
          fontSize: '13px',
          color: '#484c50',
          lineHeight: 1.05,
          opacity: 0.85,
          height: '50px',
          width: '60%',
          borderRadius: '3px',
          resize: 'none',
          boxShadow: '1px 2px 1px 0 rgba(31, 38, 135, 0.37)',
          border: '1px solid rgba(255, 255, 255, 0.18)',
        }}
        value={textInputted}
        placeholder="Type something..."
        // Measure caret fewer times to reduce overhead
        onChange={(e) => {
          setTextInputted(e.target.value);
          measureCaret();
          // Fire a callback if provided
          if (changed) changed(e.target.value);
        }}
        onFocus={measureCaret}
        onBlur={() => {
          if (blurred){
            blurred()
        }
        }}
      />

      {/* Hidden mirror for caret calculations */}
      <div ref={mirrorRef} />

      {/* Render the bubble in a Portal */}
      <BubblePortal x={coords.x} y={coords.y} />
    </div>
  );
}

export default CustomerTextPrediction;
