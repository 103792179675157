import React from 'react';

interface HighlightMatchProps {
    text: string;
    highlight: string;
}

export const HighlightMatch: React.FC<HighlightMatchProps> = ({ text, highlight }) => {
    if (!highlight.trim()) {
        return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
        <span>
            {parts.map((part, i) =>
                regex.test(part) ? <span key={i} className="Highlighted-Text">{part}</span> : part
            )}
        </span>
    );
}; 