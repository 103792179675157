import React, { useState, useCallback, useEffect,useRef } from 'react';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import './Invoice.css';
import axios, { AxiosProgressEvent } from 'axios';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile,faXmark,faUpload } from '@fortawesome/free-solid-svg-icons'
import Loading from '../GlobalComponents/Loading';

interface FileWithPreview extends File {
    preview: string;
  }


const InvoiceUpload = () => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [estimatedTime, setEstimatedTime] = useState<string | null>(null);
  const uploadStartTimeRef = useRef<number | null>(null);
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      const mappedFiles: FileWithPreview[] = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...mappedFiles]);
    },
    []
  );


    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        multiple: true, // Allow multiple files
        maxSize: 10485760, // 10 MB per file
      });

      useEffect(() => {
        return () => {
          files.forEach((file) => URL.revokeObjectURL(file.preview));
        };
      }, [files]);


      const handleUpload = async () => {
        if (files.length === 0) {
          return;
        }
      
        // Helper function to split files into chunks of specified size
        const splitIntoChunks = (array: File[], chunkSize: number): File[][] => {
          const chunks: File[][] = [];
          for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
          }
          return chunks;
        };
      
        // Determine if we need to split into chunks
        const CHUNK_SIZE = 5;
        const fileChunks = files.length > CHUNK_SIZE ? splitIntoChunks(files, CHUNK_SIZE) : [files];
      
        try {
          setIsUploading(true);
          setUploadProgress(0);
          setUploadMessage('');
          uploadStartTimeRef.current = Date.now();
          let filesUploaded = 0;
          
      
          let totalFiles = files.length;
          let uploadedFiles = 0;
      
          for (const chunk of fileChunks) {
            const formData = new FormData();
            chunk.forEach((file) => {
              formData.append('files', file);
            });
      
            const response = await axios.post('https://admin.aaatheatingandgas.co.uk/api/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                if (progressEvent.total) {
                  const total = progressEvent.total;
                  const current = progressEvent.loaded;
                  const percentCompleted = Math.round((current / total) * 100);
      
                  // Calculate overall progress
                  const overallPercent = Math.round(((uploadedFiles + (current / total)) / totalFiles) * 100);
                  setUploadProgress(overallPercent);
      
                  // Calculate estimated time
                  if (uploadStartTimeRef.current) {
                    const elapsedTime = (Date.now() - uploadStartTimeRef.current) / 1000; // in seconds
                    const uploadSpeed = current / elapsedTime; // bytes per second
                    const remainingBytes = total - current;
                    const remainingTime = uploadSpeed > 0 ? remainingBytes / uploadSpeed : 0;
                    setEstimatedTime(`${remainingTime.toFixed(1)} seconds`);
                  }
                }
              },
            });
      
            // Update counters after successful upload of the chunk
            uploadedFiles += chunk.length;
            filesUploaded += 1;
            setUploadMessage(`${filesUploaded} of ${files.length} files uploaded.`);
          }
          
      
          setFiles([]); // Clear files after successful upload
        } catch (error: any) {
          console.error('Upload error:', error.response ? error.response.data : error.message);
          setUploadMessage(error.response ? error.response.data.error : 'Upload failed.');
        } finally {
          setIsUploading(false);
          uploadStartTimeRef.current = null;
          setEstimatedTime(null);
          setUploadProgress(100); // Ensure progress is set to 100% upon completion
        }
      };
      
    
      const removeFile = (fileName: string) => {
        setFiles(files.filter((file) => file.name !== fileName));
      };

      const thumbs = files.map((file) => (
        <div className="thumb" key={file.name}>
          <div className="thumb-inner">
            {file.type.startsWith('image/') ? (
              <img src={file.preview} alt={file.name} />
            ) : (
              <div className="file-icon">📄</div>
            )}
            <p>{file.name}</p>
            <button onClick={() => removeFile(file.name)}>Remove</button>
          </div>
        </div>
      ));


    return (
        <>
        <section className="Dropzone-Container">
      <motion.div  {...getRootProps({ className: 'Dropzone' })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag & drop some files here, or click to select files</p>
        )}
      </motion.div>
      <div className='Files-Container'>
      <div className='File-List-Container'>
        {files.map((file) => (
        <div className="Uploaded-File" key={file.name}>
          <div className="Uploaded-File-Inner">
          <FontAwesomeIcon size='2x' color='#071224' icon={faFile}/>
            <p> {file.name}</p>
            <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.8}} className='Remove-File' onClick={() => removeFile(file.name)}><FontAwesomeIcon size='2x' color='red' icon={faXmark}/></motion.button>
          </div>
        </div> 
      ))}
      </div>
     
        <motion.button whileHover={{scale:files.length > 0 || isUploading ? 1.1 : 1}} whileTap={{scale:files.length > 0 || isUploading ? 0.8 : 1}} className="Upload-Button" onClick={handleUpload}>
          {isUploading ? <p>Uploading...</p> : <p>Upload <FontAwesomeIcon color='white' icon={faUpload}/></p>}
        </motion.button>
     
      
        {isUploading && (<div className="Progress">
          <Loading size={0.4}/>
        </div>)}
      
        {uploadMessage && <h1 className="Upload-Message">{uploadMessage}</h1>}
      </div>
    </section>

        </>
      
    );
};

export default InvoiceUpload;