import React, { useEffect, useState, useRef } from 'react';
import './Invoices.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import PocketBase from 'pocketbase';
import InvoiceGenerator from './Invoice';
import Customers from './Customers'
import eventEmitter from '../GlobalComponents/EventEmitter';
import { HighlightMatch } from '../GlobalComponents/HighlightMatch';

const Invoices = () => {
    const [invoices, setInvoices] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [viewingInvoice, setViewingInvoice] = useState<any>(null);
    const [viewingCustomer, setViewingCustomer] = useState<any>(null);
    const [customers, setCustomers] = useState<{ [key: string]: any }>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<{ [key: string]: any[] }>({});
    const [allInvoices,setAllInvoices] = useState<any>([])

    const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase');
    pb.autoCancellation(false);

    const perPage = 10; // how many invoices per page
    const [page, setPage] = useState<number>(1); // current page number
    const observerTarget = useRef<HTMLDivElement | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);

    // Instead of displayCount, we rely on page * perPage to know how many to show
    const displayCount = page * perPage;

    const fetchInvoices = async (initial = false) => {
        setIsLoading(true);
        try {
            // Fetch invoices for the current page
            const response = await pb.collection('Invoices').getList(page, perPage, { sort: '-Number' });
            const newInvoices = response.items;

            
            setInvoices(prev => {
                if (initial) {
                    return newInvoices;
                } else {
                    // Create a map of existing invoice IDs for quick lookup
                    const existingIds = new Set(prev.map(inv => inv.id));
                    const filteredNewInvoices = newInvoices.filter(inv => !existingIds.has(inv.id));
                    return [...prev, ...filteredNewInvoices];
                }
            });

            // Extract unique customer IDs from the newly fetched invoices
            const uniqueCustomerIds = Array.from(new Set(newInvoices.map(i => i.Customer).filter(Boolean)));
            
            if (uniqueCustomerIds.length > 0) {
                // Fetch all customers in parallel
                const customerPromises = uniqueCustomerIds.map(id => pb.collection('Customers').getOne(id));
                const customerData = await Promise.all(customerPromises);

                const newCustomerDict = { ...customers };
                customerData.forEach(cust => {
                    newCustomerDict[cust.id] = cust;
                });
                setCustomers(newCustomerDict);
            }
        } catch (error) {
            console.error('Error fetching invoices:', error);
            if (initial) setInvoices([]);
        } finally {
            setIsLoading(false);
        }
    };


    const fetchAllInvoices = async () => {
        try {
            const response = await pb.collection('Invoices').getFullList({ sort: '-Number' });
            setAllInvoices(response)
        } catch (error) {
            console.error('Error fetching all invoices:', error);
        }
    }



    const handleSearch = async (term: string) => {
        setSearchTerm(term);
        const searchTermTrimmed = term.trim().toLowerCase();

        if (searchTermTrimmed === '') {
            setSearchResults({});
            return;
        }

        try {
            const results: { [key: string]: any[] } = {
                'By Number': [],
                'By Date': [],
                'By Customer': [],
                'By Amount': [],
                'By Description': []
            };

            const numberSet = new Set<string>();
            const dateSet = new Set<string>();
            const customerSet = new Set<string>();
            const amountSet = new Set<string>();
            const descSet = new Set<string>();

            allInvoices.forEach((invoice: any) => {
                // By Number
                if (invoice.Number.toString().includes(searchTermTrimmed)) {
                    if (!numberSet.has(invoice.id)) {
                        results['By Number'].push(invoice);
                        numberSet.add(invoice.id);
                    }
                }

                // By Date
                const formattedDate = new Date(invoice.Date).toLocaleDateString().toLowerCase();
                if (formattedDate.includes(searchTermTrimmed)) {
                    if (!dateSet.has(invoice.id)) {
                        results['By Date'].push(invoice);
                        dateSet.add(invoice.id);
                    }
                }

                // By Customer
                const customer = customers[invoice.Customer];
                if (customer?.Name?.toLowerCase().includes(searchTermTrimmed)) {
                    if (!customerSet.has(invoice.id)) {
                        results['By Customer'].push(invoice);
                        customerSet.add(invoice.id);
                    }
                }

                // By Amount
                const amountStr = invoice.Total.toString();
                if (amountStr.includes(searchTermTrimmed)) {
                    if (!amountSet.has(invoice.id)) {
                        results['By Amount'].push(invoice);
                        amountSet.add(invoice.id);
                    }
                }

                // By Description
                const desc = invoice.Description?.toLowerCase();
                if (desc && desc.includes(searchTermTrimmed)) {
                    if (!descSet.has(invoice.id)) {
                        results['By Description'].push(invoice);
                        descSet.add(invoice.id);
                    }
                }
            });

            setSearchResults(results);
        } catch (error) {
            console.error('Search error:', error);
            setSearchResults({});
        }
    };

    useEffect(() => {
        // Initial fetch on mount
        fetchInvoices(true);
        fetchAllInvoices()
    }, []);

    useEffect(() => {
        fetchInvoices(false);
    }, [page]);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const suffix = (day: number) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${month} ${day}${suffix(day)}, ${year}`;
    };

    const handleViewCustomer = (id: any, event: any) => {
        if (event.target === event.currentTarget) {
            setViewingCustomer(id)
        }
    }

    const handleViewInvoice = (id: any, event: any) => {
        if (event.target === event.currentTarget) {
            setViewingInvoice(id)
        }
    }

    useEffect(() => {
        // Reset observer if needed
        if (observer.current) {
            observer.current.disconnect();
        }

        // Only set up observer if not searching and more invoices are available
        if (!searchTerm && invoices.length >= displayCount) {
            observer.current = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        // Fetch the next page
                        setPage(prevPage => prevPage + 1);
                    }
                },
                { threshold: 1.0 }
            );

            if (observerTarget.current) {
                observer.current.observe(observerTarget.current);
            }
        }

        return () => {
            if (observer.current && observerTarget.current) {
                observer.current.unobserve(observerTarget.current);
            }
        };
    }, [invoices, searchTerm, displayCount]);

    // Determine which invoice index should have the ref.
    // After loading initial 10, we place the observer on the 5th invoice (index = 4) of that batch.
    const observerIndex = displayCount - 6;
    const shouldObserve = observerIndex >= 0 && observerIndex < displayCount && !searchTerm;

    if (viewingCustomer) {
        return (
            <Customers initLoading={true} identifier={viewingCustomer} closeView={() => setViewingCustomer('')}/>
        )
    }

    if (viewingInvoice) {
        return (
            <InvoiceGenerator initLoading={true} identifier={viewingInvoice} closeView={() => setViewingInvoice('')}/>
        )
    }

    if (!isLoading && invoices.length === 0) {
        return (
            <div className='Invoices'>
                <div className='Invoices-Header'>
                    <h1 className='Invoices-Title'>Invoices</h1>
                </div>
                <p>No Internet Connection.</p>
            </div>
        );
    }

    return (
        <div className='Invoices'>
            <div className='Invoices-Header'>
                <h1 className='Invoices-Title'>Invoices</h1>
                <div className='Search-Container'>
                    <input 
                        onChange={(e) => handleSearch(e.target.value)} 
                        type='text'
                        placeholder='Search invoices...'
                    />
                    <FontAwesomeIcon color='#0f57fb' icon={faMagnifyingGlass} size='1x' />
                </div>
            </div>
            <div className='Invoices-List'>
                {searchTerm ? (
                    Object.entries(searchResults)
                        .map(([category, results]) => (
                            results.length > 0 && (
                                <div key={category} className='Search-Category'>
                                    <h2 className='Category-Title'>{category}</h2>
                                    {results.map((invoice: any) => (
                                        <motion.div
                                            title='Open Invoice'
                                            layout
                                            key={invoice.id}
                                            className='Invoice-Item'
                                            style={{marginBottom:'0px',marginTop:'10px'}}
                                            whileHover={{ scale: 0.95 }}
                                            whileTap={{ scale: 0.9 }}
                                            onClick={(e) => handleViewInvoice(invoice.id,e)}
                                        >
                                            <div className='Invoice-Details'>
                                                <h2>{invoice.Number}</h2>
                                                <p>Date: {formatDate(invoice.Date)}</p>
                                                <motion.p
                                                    whileHover={{ scale: 0.95,paddingTop:'5px',paddingBottom:'5px',backgroundColor:'white',color:'#071224',borderRadius:'3px' }}
                                                    whileTap={{ scale: 0.9 }} 
                                                    onClick={() => setViewingCustomer(customers[invoice.id]?.id)}
                                                >
                                                    {category === 'By Customer' && customers[invoice.Customer]?.Name ? 
                                                        <HighlightMatch text={customers[invoice.Customer].Name} highlight={searchTerm} /> 
                                                        : (customers[invoice.Customer]?.Name || '')}
                                                </motion.p>
                                                <p>Total: £{invoice.Total.toFixed(2)}</p>
                                                
                                                {category === 'By Description' && (
                                                    <p>Description: <HighlightMatch text={invoice.Description} highlight={searchTerm} /></p>
                                                )}
                                            </div>
                                        </motion.div>
                                    ))}
                                </div>
                            )
                        ))
                ) : (
                    <>
                        {invoices.slice(0, displayCount).map((invoice: any, index: number) => {
                            const formattedTotal = `£${invoice.Total.toFixed(2)}`;
                            const customer = customers[invoice.Customer];
                            // Attach ref to the invoice at observerIndex (5th invoice of the current batch)
                            const refProp = (shouldObserve && index === observerIndex) ? { ref: observerTarget } : {};
                            
                            return (
                                <motion.div
                                    title='Open Invoice'
                                    layout
                                    key={invoice.id}
                                    className='Invoice-Item'
                                    whileHover={{ scale: 0.95 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={(e) => handleViewInvoice(invoice.id,e)}
                                    {...refProp}
                                >
                                    <div className='Invoice-Details'>
                                        <h2>Invoice {invoice.Number}</h2>
                                        <p>{formatDate(invoice.Date)}</p>
                                        <motion.div 
                                            whileHover={{ scale: 0.95,paddingTop:'5px',paddingBottom:'5px',backgroundColor:'white',color:'#071224',borderRadius:'3px' }} 
                                            whileTap={{ scale: 0.9 }} 
                                            onClick={(evt) => handleViewCustomer(customer?.id, evt)}
                                            title='View Customer'
                                        >
                                            {customer?.Name || ''}
                                        </motion.div>
                                        <p>Total: {formattedTotal}</p>
                                    </div>
                                    <div className='Invoice-Actions'>
                                        {/* Consider lazy loading these images if still slow */}
                                        <img src={`https://admin.aaatheatingandgas.co.uk/aaatbase/api/files/Invoices/${invoice.id}/${invoice.Thumbnail}`} alt="Invoice Thumbnail"/>
                                    </div>
                                </motion.div>
                            );
                        })}
                    </>
                )}
            </div>
            
        </div>
    );
};

export default Invoices;
