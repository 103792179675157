import React, {useEffect, useState} from 'react';
import './Invoice.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsSpin,faXmark,faArrowLeft, faPencil, faEnvelope, faSave, faDownload, faUpload, faCogs, faTachometerFast, faTachometerAlt, faTachometerAltFast, faMagnifyingGlass,faTrash } from '@fortawesome/free-solid-svg-icons'
import { Document, Page, Text, View, Image, StyleSheet, PDFDownloadLink,pdf } from '@react-pdf/renderer';
import InvoiceViewer from './InvoiceViewer'
import PocketBase from 'pocketbase';
import eventEmitter from '../GlobalComponents/EventEmitter';
import InvoiceUpload from './InvoiceUpload';
import { motion } from 'framer-motion';
import Loading from '../GlobalComponents/Loading';
import { Primary } from '../Constants/Colours';
import CustomerTextPrediction from '../GlobalComponents/CustomerPrediction'

interface ListItem {
    'description': string;
    'quantity': number;
    'rate': number;
}

interface SearchResult {

    category: string;

    invoice: any;

    matchedText: string;

    date?: string;

}

interface InvoiceGeneratorProps {
    identifier?: string;
    closeView?: any;
    initLoading?: boolean;
}


function InvoiceGenerator({ identifier, closeView, initLoading }: InvoiceGeneratorProps) {
    const [newInvoice,setNewInvoice] = useState(false)
    const [discount,setDiscount] = useState(false)
    const [shipping,setShipping] = useState(false)
    const [chosenLogo,setChosenLogo] = useState<any>()
    const [invoiceFrom,setInvoiceFrom] = useState('')
    const [invoiceBillTo,setInvoiceBillTo] = useState('')
    const [invoiceNumber,setInvoiceNumber] = useState(1)
    const [invoiceDraftNumber,setInvoiceDraftNumber] = useState(1)
    const [invoiceDate,setInvoiceDate] = useState<any>()
    const [invoicePaymentTerms,setInvoicePaymentTerms] = useState('')
    const [invoiceDueDate,setInvoiceDueDate] = useState<any>()
    const [invoicePONumber,setInvoicePONumber] = useState('')
    const [invoiceNotes,setInvoiceNotes] = useState('')
    const [invoiceTerms,setInvoiceTerms] = useState('')
    const [invoiceSubTotal,setInvoiceSubTotal] = useState(0)
    const [invoiceTax,setInvoiceTax] = useState(0)
    const [invoiceDiscount,setInvoiceDiscount] = useState(false)
    const [invoiceShipping,setInvoiceShipping] = useState(false)
    const [invoiceDiscountPercentage,setInvoiceDiscountPercentage] = useState(0)
    const [invoiceShippingCost,setInvoiceShippingCost] = useState(0)
    const [invoiceTotal,setInvoiceTotal] = useState(0)
    const [invoiceAmountPaid,setInvoiceAmountPaid] = useState(0)
    const [invoiceBalanceDue,setInvoiceBalanceDue] = useState(0)
    const [invoiceId,setInvoiceId] = useState('')
    const [lineItems, setLineItems] = useState([{ description: '', quantity: 1, rate: 0, id: '' }]);
    const [invoiceDrafts,setInvoiceDrafts] = useState<any>([])
    const [invoices,setInvoices] = useState<any>([])
    const [itemUpdate,setItemUpdate] = useState(false)
    const [userId,setUserId] = useState('')
    const [countNumber,setCountNumber] = useState(0)
    const [pdfUrl,setPdfUrl] = useState('')
    const [openInvoice,setOpenInvoice] = useState(false)
    const [editInvoice,setEditInvoice] = useState(false)
    const [openDraft,setOpenDraft] = useState(false)
    const [uploadInvoice,setUploadInvoice] = useState(false)
    const [loading,setLoading] = useState(false)
    const [imageUrl,setImageUrl] = useState('')
    const [isLoading,setIsLoading] = useState(false)
    const [invoicePrompt, setInvoicePrompt] = useState('')
    const [openPrompt, setOpenPrompt] = useState(false)
    const [onlyInvoices, setOnlyInvoices] = useState(false)
    const [onlyDrafts, setOnlyDrafts] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [alteringNotes,setAlteringNotes] = useState(false);
    const [alteringFrom,setAlteringFrom] = useState(false);
    const [displayFromEdit,setDisplayFromEdit] = useState(false);
    const [displayNotesEdit,setDisplayNotesEdit] = useState(false);
    const [searchResults, setSearchResults] = useState<{ [key: string]: SearchResult[] }>({});

    const invidentifier = identifier || ''
    const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase')
    const user = process.env.REACT_APP_USER || ''
    const pass = process.env.REACT_APP_PASS || ''
    pb.admins.authWithPassword(user,pass)
    pb.autoCancellation(false)

    const getCount = async () => {
        const count = await pb.collection('Users').getFullList()
        setInvoiceNumber(count[0].Invoice_Number + 1)
        setInvoiceDraftNumber(count[0].Invoice_Draft_Number + 1)
        setUserId(count[0].id)
        const imageLoc = pb.files.getUrl(count[0],count[0].Logo)
        setImageUrl(imageLoc)
        return {'invoiceNum':count[0].Invoice_Number + 1,'draftNum':count[0].Invoice_Draft_Number + 1,'id':count[0].id}
    }

    async function fetchLineItemsData(ids : any) {
        try {
            const items = await Promise.all(ids.map((id:any) => pb.collection('Items').getOne(id)));
            setLineItems(items.map(item => ({
                description: item.Description,
                quantity: item.Quantity,
                rate: item.Rate,
                id: item.id,
            })));
        } catch (error) {
            console.error("Failed to fetch line items data:", error);
        }
    }

    const CreateDescription = async (ident : any) => {
        const data = {
            id: ident,
        };
        try {
            const response = await fetch('https://admin.aaatheatingandgas.co.uk/api/create-description', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),  // Convert data to JSON string
            })
            const result = await response.json()
        } catch (error) {
            console.log('Failed to create description',error)
        } finally {
            setIsLoading(false)
        }
    }

    const CreateThumbnail = async (ident : any) => {
        const data = {
            id: ident,
        };
        try {
            const response = await fetch('https://admin.aaatheatingandgas.co.uk/api/create-thumbnail', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),  // Convert data to JSON string
            })
            const result = await response.json()
        } catch (error) {
            console.log('Failed to create description',error)
        } finally {
            setIsLoading(false)
        }
    }

    const DriveBackUp = async (ident : any) => {
        const data = {
            id: ident,
        };
        try {
            const response = await fetch('https://admin.aaatheatingandgas.co.uk/api/drive-backup', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),  // Convert data to JSON string
            })
            const result = await response.json()
        } catch (error) {
            console.log('Failed to create description',error)
        } finally {
            setIsLoading(false)
        }
    }

    const GenerateInvoice = async () => {
        setIsLoading(true)
        const data = {prompt: invoicePrompt}
        try {
            const response = await fetch('https://admin.aaatheatingandgas.co.uk/api/generate-invoice', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),  // Convert data to JSON string
            })
            const resjson = await response.json()
            const resinv = await pb.collection('Invoice_Drafts').getOne(resjson.id)
            setOpenPrompt(false)
            handleOpenDraft(resinv)
        } catch {
            console.log('Failed to generate invoice')
            setIsLoading(false)
        }

        
        
            


    }

    const closeInvoice = () => {
        setIsLoading(true)
        if (newInvoice) {
            const isDraftUnchanged = (
            
                invoiceBillTo === '' &&
                invoiceDate === undefined &&
                
                invoiceDueDate === undefined &&
                invoicePONumber === '' &&
            
                invoiceTerms === '' &&
                invoiceSubTotal === 0 &&
                invoiceTax === 0 &&
                invoiceDiscountPercentage === 0 &&
                invoiceShippingCost === 0 &&
                invoiceTotal === 0 &&
                invoiceAmountPaid === 0 &&
                invoiceBalanceDue === 0 &&
                lineItems.length === 1 &&
                lineItems[0].description === ''
                
            );

            if (isDraftUnchanged) {
                pb.collection('Users').update(userId, {Invoice_Draft_Number:invoiceDraftNumber - 1})
                pb.collection('Items').delete(lineItems[0].id)
                pb.collection('Invoice_Drafts').delete(invoiceId)
                    .then(() => {
                        console.log('Draft deleted successfully');
                    })
                    .catch((error) => {
                        console.log('Failed to delete draft:', error);
                    });
            }
        }
        setEditInvoice(false)
        setNewInvoice(false)
        setOpenInvoice(false)
        setOpenDraft(false)
        setDiscount(false);
        setShipping(false);
        setChosenLogo(undefined);
        setInvoiceFrom('');
        setInvoiceBillTo('');
        setInvoiceDate(undefined);
        setInvoicePaymentTerms('');
        setInvoiceDueDate(undefined);
        setInvoicePONumber('');
        setInvoiceNotes('');
        setInvoiceTerms('');
        setInvoiceSubTotal(0);
        setInvoiceTax(0);
        setInvoiceDiscount(false);
        setInvoiceShipping(false);
        setInvoiceDiscountPercentage(0);
        setInvoiceShippingCost(0);
        setInvoiceTotal(0);
        setInvoiceAmountPaid(0);
        setInvoiceBalanceDue(0);
        setLineItems([{ description: '', quantity: 1, rate: 0, id: '' }]);
        setInvoiceDrafts([]);
        setInvoices([]);
        setUserId('');
        setInvoiceId('')
        setCountNumber(0);
        setIsLoading(false)
        


    }


    const getDrafts = async () => {
        try {
            const drafts = await pb.collection('Invoice_Drafts').getFullList({ sort: '-created' })
      
            setInvoiceDrafts(drafts)
        } catch {
            setInvoiceDrafts([])
        }
        
    }

    const getInvoices = async () => {
        try {
            const inv = await pb.collection('Invoices').getFullList()
            inv.sort((a: any, b: any) => b.Number - a.Number);
            setInvoices(inv)
        } catch {
            setInvoices([])
        }
        
    }

    useEffect(() => {
        
        if (openDraft || newInvoice) handleSaveDraft();

    }, [invoiceFrom, invoiceBillTo, invoiceNumber, invoiceDate, invoicePaymentTerms, invoiceDueDate, invoicePONumber, invoiceNotes, invoiceTerms, invoiceSubTotal, invoiceTax, invoiceDiscountPercentage, invoiceShippingCost, invoiceTotal, invoiceAmountPaid, invoiceBalanceDue])


    const handleSaveDraft = async (lineItems?:any) => {
        const idList : any = []

        


        const invoiceInfo = {
            'From':invoiceFrom,
            'Bill_To':invoiceBillTo,
            'Number':invoiceNumber,
            'Date':invoiceDate,
            'Payment_Terms':invoicePaymentTerms,
            'Due_Date':invoiceDueDate,
            'PO_Number':invoicePONumber,
            'Notes':invoiceNotes,
            'Terms':invoiceTerms,
            'Sub_Total':invoiceSubTotal,
            'Tax':invoiceTax,
            'Discount':invoiceDiscountPercentage,
            'Shipping':invoiceShippingCost,
            'Total':invoiceTotal,
            'Amount_Paid':invoiceAmountPaid,
            'Balance_Due':invoiceBalanceDue,
      
        }
        try { 
        pb.collection('Invoice_Drafts').update(invoiceId,invoiceInfo)
        } catch (error)  {
        console.log('Failed to save draft:',error)
        
        }
    }

    const handleOpenInvoice = async (invoice : any) => {
        if (typeof invoice === 'string') {
            invoice = await pb.collection('Invoices').getOne(invoice);
        }
        const url = pb.getFileUrl(invoice, invoice.PDF);
        setPdfUrl(url);
        setInvoiceId(invoice.id)
        setOpenInvoice(true)
    }

    const handleOpenDraft = async (draft : any) => {
        setIsLoading(true)
        setOpenInvoice(false)
        const count = await pb.collection('Users').getFullList()
        setUserId(count[0].id)
        const imageLoc = pb.files.getUrl(count[0],count[0].Logo)
        setImageUrl(imageLoc)
        setInvoiceFrom(draft.From || '')
        setInvoiceBillTo(draft.Bill_To || '')
        setInvoicePONumber(draft.PO_Number || '')
        const today = new Date().toISOString().slice(0, 10);
        setInvoiceDate(draft.Date.slice(0,10) || today)
        setInvoicePaymentTerms(draft.Payment_Terms || '')
        setInvoiceDueDate(draft.Due_Date.slice(0,10) || today);
        setInvoiceNotes(draft.Notes || '')
        setInvoiceTerms(draft.Terms || '')
        setInvoiceSubTotal(draft.Sub_Total || 0)
        setInvoiceTax(draft.Tax || 0)
        if (draft.Discount > 0) {
            setDiscount(true)
        }
        if (draft.Shipping > 0) {
            setShipping(true)
        }
        setInvoiceDiscountPercentage(draft.Discount || 0)
        setInvoiceShippingCost(draft.Shipping || 0)
        setInvoiceTotal(draft.Total || 0)
        setInvoiceAmountPaid(draft.Amount_Paid || 0)
        setInvoiceBalanceDue(draft.Balance_Due || 0)
        setInvoiceNumber(draft.Number)
        setInvoiceId(draft.id)
        await fetchLineItemsData(draft.Items)
        setOpenDraft(true)
        setIsLoading(false)
        } 
    const handleEditInvoice = async (invoiceID:any) => {
        setIsLoading(true)
        setOpenInvoice(false)
        const count = await pb.collection('Users').getFullList()
        const draft = await pb.collection('Invoices').getOne(invoiceID)
        setUserId(count[0].id)
        const imageLoc = pb.files.getUrl(count[0],count[0].Logo)
        setImageUrl(imageLoc)
        setInvoiceFrom(draft.From || '')
        setInvoiceBillTo(draft.Bill_To || '')
        const today = new Date().toISOString().slice(0, 10);
        setInvoicePONumber(draft.PO_Number || '')
        setInvoiceDate(draft.Date.slice(0,10) || today)
        setInvoicePaymentTerms(draft.Payment_Terms || '')
        setInvoiceDueDate(draft.Due_Date.slice(0,10) || today);
        setInvoiceNotes(draft.Notes || '')
        setInvoiceTerms(draft.Terms || '')
        setInvoiceSubTotal(draft.Sub_Total || 0)
        setInvoiceTax(draft.Tax || 0)
        if (draft.Discount > 0) {
            setDiscount(true)
        }
        if (draft.Shipping > 0) {
            setShipping(true)
        }
        setInvoiceDiscountPercentage(draft.Discount || 0)
        setInvoiceShippingCost(draft.Shipping || 0)
        setInvoiceTotal(draft.Total || 0)
        setInvoiceAmountPaid(draft.Amount_Paid || 0)
        setInvoiceBalanceDue(draft.Balance_Due || 0)
        setInvoiceNumber(draft.Number)
        setInvoiceId(draft.id)
        await fetchLineItemsData(draft.Items)
        setEditInvoice(true)
        setIsLoading(false)
        } 

    const handleAddLine = async () => {
        const id = await pb.collection('Items').create({ 'Description': '', 'Quantity': 1, 'Rate': 0 });
        const newLine = { description: '', quantity: 1, rate: 0, id : id.id };
        setLineItems([...lineItems, newLine]);
        const newItemIDlist = lineItems.map((item) => item.id)
        const invoiceInfo = { 'Items': [...newItemIDlist,id.id] }
        if (newInvoice || openDraft){
            pb.collection('Invoice_Drafts').update(invoiceId,invoiceInfo)
        } else {
            pb.collection('Invoice').update(invoiceId,invoiceInfo)
        }
        
    };

    const handleRemoveLine = async (index: any) => {
        await new Promise<void>(resolve => {
            setLineItems(prevItems => {
                const newItems = prevItems.filter((_, i) => i !== index);
                // Use setTimeout to ensure this runs after the state update
                setTimeout(resolve, 300);
                return newItems;
            });
            const newItems = lineItems.filter((_, i) => i !== index);
            const newItemIDlist = newItems.map((item) => item.id)
            const invoiceInfo = { 'Items': newItemIDlist }
            pb.collection('Invoice_Drafts').update(invoiceId,invoiceInfo)

              
        })
        
    };
    const handleSaveInvoice = async () => {
        setIsLoading(true)
        // for editting invoices
        // Prepare invoice data with 'Items' as a list
        const invoiceInfo = {
            'From': invoiceFrom,
            'Bill_To': invoiceBillTo,
            'Number': invoiceNumber,
            'Date': invoiceDate,
            'Payment_Terms': invoicePaymentTerms,
            'Due_Date': invoiceDueDate,
            'PO_Number': invoicePONumber,
            'Notes': invoiceNotes,
            'Terms': invoiceTerms,
            'Sub_Total': invoiceSubTotal,
            'Tax': invoiceTax,
            'Discount': invoiceDiscountPercentage,
            'Shipping': invoiceShippingCost,
            'Total': invoiceTotal,
            'Amount_Paid': invoiceAmountPaid,
            'Balance_Due': invoiceBalanceDue,
            'Items': lineItems.map(item => item.id), // Items as an array of IDs
        };
    
        try {
            let existingInvoice = null;
            const filter = `Number = "${invoiceNumber}"`;
    
            try {
                existingInvoice = await pb.collection('Invoices').getFirstListItem(filter);
            } catch (err) {
                // No existing invoice found
            }
    
            let invoiceIdent;
    
            if (existingInvoice) {
                // Update the existing invoice
                const updatedInvoice = await pb.collection('Invoices').update(existingInvoice.id, invoiceInfo);
                console.log('Invoice updated successfully:', existingInvoice.id);
                invoiceIdent = updatedInvoice.id;
                CreateDescription(updatedInvoice.id)
            } else {
                // Create a new invoice
                const newInvoice = await pb.collection('Invoices').create(invoiceInfo);
                console.log('Invoice created successfully:', newInvoice.id);
                invoiceIdent = newInvoice.id;
                CreateDescription(newInvoice.id)
            }
    
            // Now upload the PDF file separately
            // Prepare FormData with only the PDF
            const formData = new FormData();
            const blob = await pdf(<MyDocument />).toBlob();
            formData.append('PDF', blob,`Invoice ${invoiceNumber}.pdf`);
    
            // Upload the PDF to the existing invoice
            const pdfupdate = await pb.collection('Invoices').update(invoiceIdent, formData);

            
            CreateThumbnail(pdfupdate.id)
            DriveBackUp(pdfupdate.id)
         
            
          
        } catch (error) {
            console.log('Failed to save invoice:', error);
        }
    };

    


    const SaveInvoice = async () => {
        setIsLoading(true)
        // Prepare invoice data with 'Items' as a list
        const invoiceInfo = {
            'From': invoiceFrom,
            'Bill_To': invoiceBillTo,
            'Number': invoiceNumber,
            'Date': invoiceDate,
            'Payment_Terms': invoicePaymentTerms,
            'Due_Date': invoiceDueDate,
            'PO_Number': invoicePONumber,
            'Notes': invoiceNotes,
            'Terms': invoiceTerms,
            'Sub_Total': invoiceSubTotal,
            'Tax': invoiceTax,
            'Discount': invoiceDiscountPercentage,
            'Shipping': invoiceShippingCost,
            'Total': invoiceTotal,
            'Amount_Paid': invoiceAmountPaid,
            'Balance_Due': invoiceBalanceDue,
            'Items': lineItems.map(item => item.id), // Items as an array of IDs
        };
    
        try {
            let existingInvoice = null;
            const filter = `Number = "${invoiceNumber}"`;
    
            try {
                existingInvoice = await pb.collection('Invoices').getFirstListItem(filter);
            } catch (err) {
                // No existing invoice found
            }
    
            let invoiceIdent;
    
            if (existingInvoice) {
                // Update the existing invoice
                const updatedInvoice = await pb.collection('Invoices').update(existingInvoice.id, invoiceInfo);
                console.log('Invoice updated successfully:', existingInvoice.id);
                invoiceIdent = updatedInvoice.id;
                CreateDescription(updatedInvoice.id)
            } else {
                // Create a new invoice
                const newInvoice = await pb.collection('Invoices').create(invoiceInfo);
                console.log('Invoice created successfully:', newInvoice.id);
                invoiceIdent = newInvoice.id;
                CreateDescription(newInvoice.id)
            }
    
            // Now upload the PDF file separately
            // Prepare FormData with only the PDF
            const formData = new FormData();
            const blob = await pdf(<MyDocument />).toBlob();
            formData.append('PDF', blob,`Invoice ${invoiceNumber}.pdf`);
    
            // Upload the PDF to the existing invoice
            const pdfupdate = await pb.collection('Invoices').update(invoiceIdent, formData);
            const number = await getCount()
            console.log(number)
            if (invoiceNumber === number.invoiceNum) {
            const update = {'Invoice_Number':invoiceNumber,
                            'Invoice_Draft_Number':number.draftNum - 1,
            }
            await pb.collection('Users').update(userId,update)
            } else {
                console.log('Invoice Number not updated:',invoiceNumber)
                console.log(number.invoiceNum)
            }
            if (openDraft || newInvoice) {
                await pb.collection('Invoice_Drafts').delete(invoiceId) 
            } 
            closeInvoice()
            handleOpenInvoice(pdfupdate)
            CreateThumbnail(pdfupdate.id)
            DriveBackUp(pdfupdate.id)
            
        } catch (error) {
            console.log('Failed to save invoice:', error);
        }
    };
    

    const handleSaveAndSendInvoice = async () => {
        await SaveInvoice()
        const data = { id: invoiceId };
        eventEmitter.emit('recieveID', data);
        eventEmitter.emit('ToggleModal')
    }

    const handleSendInvoice = async () => {
        setLoading(true)
        const data = { id: invoiceId };
        eventEmitter.emit('recieveID', data);
        eventEmitter.emit('ToggleModal')
    }

    const DeleteEvent = async (id : any) => {
        setLoading(true)
        const data = { id: id };
        eventEmitter.emit('deleteEvent', data);
    }

    useEffect(() => {
        const DeletedEmit = (id : any) => {
            pb.collection('Invoices').delete(id.id);
            setInvoices(invoices.filter((invoice: any) => invoice.id !== id.id));
            setLoading(false);
            
        }
        eventEmitter.on('DeletedEmit',DeletedEmit);
    
        // Clean up the event listener when the component unmounts
        return () => {
          eventEmitter.off('DeletedEmit',DeletedEmit);
        };
      }, []);

    useEffect(() => {
        eventEmitter.on('ModalLoaded', () => setLoading(false));
    
        // Clean up the event listener when the component unmounts
        return () => {
          eventEmitter.off('ModalLoaded', () => setLoading(false));
        };
      }, []);
    
    useEffect(() => {
        if (initLoading) {
            setIsLoading(true)
        }
    }, [])
    
    useEffect(() => {
        if (identifier) {
            setInvoiceId(identifier)
            setTimeout(() => {
                handleOpenInvoice(identifier)
                setIsLoading(false)
            },500)
            
        }
    }, [])




    const handleChange = async (index : any, field : any, value : any) => {
        const newLineItems = lineItems.map((item, i) => {
            if (i === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setLineItems(newLineItems);
        const itemToUpdate = newLineItems[index];
        let dictfield = ''
        switch (field) {
            case 'description':
                dictfield = 'Description';
                break;
            case 'quantity':
                dictfield = 'Quantity';
                break;
            case 'rate':
                dictfield = 'Rate';
                break;
            default:
                console.error('Invalid field:', field);
                return;
        }
        try {
            console.log('Updating item on PocketBase:', itemToUpdate.id, dictfield, value);
            await pb.collection('Items').update(itemToUpdate.id, {
                [dictfield]: value
            });
            console.log('Item updated successfully on PocketBase');
        } catch (error) {
            console.error('Error updating item on PocketBase:', error);
        }
        
    };

    const handleFrom = (text : any) => {
        setInvoiceFrom(text)
    }

    const handleBillTo = (text : any) => {
       
        setInvoiceBillTo(text)
    }

    const handleNumber = async (text : any) => {
        setInvoiceNumber(text)
    }

    const handleDate = (text : any) => {
       
        setInvoiceDate(text)
    }

    const handlePaymentTerms = (text : any) => {
        setInvoicePaymentTerms(text)
    }

    const handleDueDate = (text : any) => {
  
        setInvoiceDueDate(text)
    }

    const handlePONumber = (text : any) => {
        setInvoicePONumber(text)
    }

    const handleNotes = (text : any) => {
        setInvoiceNotes(text)
    }

    const handleTerms = (text : any) => {
        setInvoiceTerms(text)
    }

    const handleTax = (text : any) => {
        setInvoiceTax(parseFloat(text) || 0)
    }

    const handleDiscount = (text : any) => {
        setInvoiceDiscountPercentage(parseFloat(text) || 0)
    }

    const handleShipping = (text : any) => {
        setInvoiceShippingCost(parseFloat(text) || 0)
    }

    const handleAmountPaid = (text : any) => {
        setInvoiceAmountPaid(text)
    }

    const calculateSubTotal = () => {
        let subtotal = 0; // Step 1: Initialize subtotal to 0
      
        lineItems.forEach(item => { // Step 2: Loop through each line item
          const itemTotal = item.quantity * item.rate; // Step 3: Multiply quantity by rate
          subtotal += itemTotal; // Step 4: Add to subtotal
        });

        subtotal = +subtotal.toFixed(2);

      
        setInvoiceSubTotal(subtotal); // Step 5: Return the subtotal
    }

    const calculateTotalAndBalanceDue = () => {
        
        calculateSubTotal(); // This updates invoiceSubTotal
      
        // Wait for invoiceSubTotal to be updated
        setTimeout(() => {
          let total = invoiceSubTotal;
      
          // Add tax if applicable
          if (invoiceTax) {
            total += (invoiceSubTotal * invoiceTax) / 100;
          }
          
          // Subtract discount if applicable
          if (invoiceDiscountPercentage > 0) {
            
            total -= (invoiceSubTotal * invoiceDiscountPercentage) / 100;
          }
          
          // Add shipping costs if applicable
          if (invoiceShippingCost > 0) {
            
            total += invoiceShippingCost;

          }

          total = +total.toFixed(2);

          setInvoiceTotal(total);

          let balanceDue = total - invoiceAmountPaid;
          balanceDue = +balanceDue.toFixed(2);
          setInvoiceBalanceDue(balanceDue);
        }, 0);
    }
  


  const handleNewInvoice = async () => {
    setIsLoading(true)
    const numberinvoice = await getCount()
    await pb.collection('Users').update(numberinvoice.id,{'Invoice_Draft_Number':numberinvoice.draftNum})
    setNewInvoice(true)
    const count = await pb.collection('Users').getOne(numberinvoice.id)
    setUserId(count.id)
    const imageLoc = pb.files.getUrl(count,count.Logo)
    setImageUrl(imageLoc)
    const today = new Date().toISOString().slice(0, 10);
    const idinvoice = await pb.collection('Invoice_Drafts').create({ 'From': count.Default_From, 'Bill_To': '', 'Draft_Number': numberinvoice.draftNum,'Number': numberinvoice.invoiceNum, 'Date': today, 'Payment_Terms': count.Default_PT, 'Due_Date': today, 'PO_Number': '', 'Notes': count.Default_Notes, 'Terms': '', 'Sub_Total': 0, 'Tax': 0, 'Discount': 0, 'Shipping': 0, 'Total': 0, 'Amount_Paid': 0, 'Balance_Due': 0, 'Items': [] });
    setInvoiceId(idinvoice.id)
    setInvoiceFrom(count.Default_From)
    setInvoicePaymentTerms(count.Default_PT)
    setInvoiceNotes(count.Default_Notes)
    setInvoiceDueDate(today)
    setInvoiceDate(today)

    const id = await pb.collection('Items').create({ 'Description': '', 'Quantity': 1, 'Rate': 0 });
    const newLine = { description: '', quantity: 1, rate: 0, id : id.id };
    setLineItems([newLine]);
    setIsLoading(false)
  }



  const styles = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent:'center',
      minHeight: '80vh',
      height:'auto',
    },
    text: {
      textAlign: 'center',
      fontSize: 24,
    },
    SafeAreaView:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'flex-start',
        height:'95%',
        width:'90%',
    },
    topView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height:'auto',
        minHeight: '235px',
        marginBottom: 10,
        },
    topViewLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '50%',
        },
    BillTo: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '50%',
        height:'auto',
        marginTop: '55px',
        marginBottom: '10px',
    },
    topViewRight: {
        display:'flex',
        height:'auto',
        minHeight:'235px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '50%',
        },
    
    }
  );

  const formatDate = (dateString: string): any => {
    if (!dateString) {
        return undefined;
    }
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

const formatDraftDate = (dateString: any) => {
    if (!dateString) {
        return undefined;
    }
  
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric',hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

 const correctType = (value:any) => {
    if (typeof value === 'string') {
        return parseFloat(value)
    } else {
        return value
    }
 }
  
  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.SafeAreaView}>
        <View style={styles.topView}>
            <View style={styles.topViewLeft}>
                <Image src={imageUrl} style={{width:'auto',height:'200px',marginBottom:'5px',borderRadius:'5px'}}/>
                <Text style={{marginBottom:'10px',fontSize:'10px'}}>{invoiceFrom}</Text>
                <View style={styles.BillTo}>
                    <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px'}}>Bill To:</Text>
                    <Text style={{fontSize:'10px',marginTop:'10px'}}>{invoiceBillTo}</Text>
                </View>



            </View>
            <View style={styles.topViewRight}>
                <View style={{display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'flex-end',height:'45px'}}>
                <Text style={{fontSize:'25px',color:'#484c50'}}>INVOICE</Text>
                <Text style={{color: '#484c50',opacity: 0.65,fontSize:'12px',marginTop:'5px'}}># {invoiceNumber}</Text>
                </View>

                <View style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-between',minHeight:'130px',height:'auto'}}>
                    <View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Date:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'10px',paddingRight:'15px',color:'#202224'}}>{formatDate(invoiceDate) || undefined}</Text>
                        </View>
                    </View>
                    <View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Payment Terms:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'10px',paddingRight:'15px',color:'#202224'}}>{invoicePaymentTerms}</Text>
                        </View>
                    </View>
                    <View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Due Date:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'10px',paddingRight:'15px',color:'#202224'}}>{formatDate(invoiceDueDate) || undefined}</Text>
                        </View>
                    </View>
                    {invoicePONumber !== '' && (<View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>PO Number:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'10px',paddingRight:'15px',color:'#2022240'}}>{invoicePONumber}</Text>
                        </View>
                    </View>)}
                    <View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end',backgroundColor:'#f0f0f0',height:'25px'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'12px',fontWeight:'bold'}}>Balance Due:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'12px',fontWeight:'bold',paddingRight:'15px'}}>GBP£{invoiceBalanceDue.toFixed(2)}</Text>
                        </View>
                    </View>

                    <View>

                    </View>
                </View>
            </View>
        </View>
        <View style={{width:'100%',height:'30px',backgroundColor:'#484c50',marginTop:'10px',borderRadius:'5px',display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}}>
            <View style={{width:'55%',display:'flex',alignItems:'flex-start',justifyContent:'flex-start'}}>
                <Text style={{color:'white',fontSize:'10px',paddingLeft:'5px'}}>Item</Text>
            </View>
            <View style={{width:'15%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                <Text style={{color:'white',fontSize:'10px',paddingLeft:'5px'}}>Quantity</Text>
            </View>
            <View style={{width:'15%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                <Text style={{color:'white',fontSize:'10px',paddingLeft:'5px'}}>Rate</Text>
            </View>
            <View style={{width:'15%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                <Text style={{color:'white',fontSize:'10px',paddingLeft:'5px'}}>Amount</Text>
            </View>


        </View>
        {lineItems.map((item, index) => (
            <View key={index} style={{width:'100%',height:'30px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}}>
                <View style={{width:'55%',display:'flex',alignItems:'flex-start',justifyContent:'flex-start'}}>
                    <Text style={{fontSize:'10px',paddingLeft:'5px'}}>{item.description}</Text>
                </View>
                <View style={{width:'15%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                    <Text style={{fontSize:'10px',paddingLeft:'5px'}}>{item.quantity}</Text>
                </View>
                <View style={{width:'15%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                    <Text style={{fontSize:'10px',paddingLeft:'5px'}}>£{correctType(item.rate).toFixed(2)}</Text>
                </View>
                <View style={{width:'15%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                    <Text style={{fontSize:'10px',paddingLeft:'5px'}}>£{(correctType(item.quantity) * correctType(item.rate)).toFixed(2)}</Text>
                </View>
            </View>
        ))}
        <View style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',minHeight:'200px',height:'auto',width:'100%'}}>
        <View style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-between',minHeight:'130px',height:'auto'}}>
                    <View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Subtotal:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'9px',paddingRight:'15px',color:'#202224'}}>GBP£{invoiceSubTotal.toFixed(2)}</Text>
                        </View>
                    </View>
                    {(invoiceDiscountPercentage > 0) && (<View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Discount({invoiceDiscountPercentage}%):</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'9px',paddingRight:'15px',color:'#202224'}}>GBP£{((invoiceSubTotal * invoiceDiscountPercentage) / 100).toFixed(2)}</Text>
                        </View>
                    </View>)}
                    <View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Tax({invoiceTax})%:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'9px',paddingRight:'15px',color:'#202224'}}>GBP£{((invoiceSubTotal * invoiceTax) / 100).toFixed(2)}</Text>
                        </View>
                    </View>
                    {(invoiceShippingCost > 0) && (<View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Shipping:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'9px',paddingRight:'15px',color:'#2022240'}}>GBP£{correctType(invoiceShippingCost).toFixed(2)}</Text>
                        </View>
                    </View>)}
                    <View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Total:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'9px',paddingRight:'15px',color:'#2022240'}}>GBP£{correctType(invoiceTotal).toFixed(2)}</Text>
                        </View>
                    </View>
                    <View style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-end'}}>
                        <View style={{width:'150px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px',}}>Amount Paid:</Text>
                        </View>
                        <View style={{width:'100px',display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
                            
                            <Text style={{fontSize:'9px',paddingRight:'15px',color:'#2022240'}}>GBP£{correctType(invoiceAmountPaid).toFixed(2)}</Text>
                        </View>
                    </View>
                    

                    <View>

                    </View>
                </View>
            </View>
            <View style={{display:'flex',alignItems:'flex-start',justifyContent:'flex-start',minHeight:'200px',height:'auto',width:'100%'}}>
        <View style={{display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'space-between',minHeight:'130px',height:'auto'}}>
        {invoiceNotes !== '' && (<View style={styles.BillTo}>
                    <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px'}}>Notes:</Text>
                    <Text style={{fontSize:'10px'}}>{invoiceNotes}</Text>
                </View>)}
                {invoiceTerms !== '' && (<View style={styles.BillTo}>
                    <Text style={{color: '#484c50',opacity: 0.65,fontSize:'10px'}}>Terms:</Text>
                    <Text style={{fontSize:'10px'}}>{invoiceTerms}</Text>
                </View>)}
                    
                    

                    <View>

                    </View>
                </View>
            </View>
        </View>
      </Page>
    </Document>
  );


  const handleSearch = async (term: string) => {

    setSearchTerm(term);

    const searchTermTrimmed = term.trim().toLowerCase();

    if (searchTermTrimmed === '') {

        setSearchResults({});

        return;

    }



    try {

        const results: { [key: string]: SearchResult[] } = {

            'By Number': [],

            'By Date': [],

            'By Description': [],

            'By Amount': []

        };



        // Use Sets to track unique matches

        const numberSet = new Set<string>();

        const dateSet = new Set<string>();

        const descSet = new Set<string>();

        const amountSet = new Set<string>();



        invoices.forEach((invoice: any) => {

            // By Number

            if (invoice.Number.toString().includes(searchTermTrimmed)) {

                if (!numberSet.has(invoice.id)) {

                    results['By Number'].push({

                        category: 'By Number',

                        invoice: invoice,

                        matchedText: `Invoice #${invoice.Number}`

                    });

                    numberSet.add(invoice.id);

                }

            }



            // By Date

            const formattedDate = formatDate(invoice.Date);

            if (formattedDate && formattedDate.toLowerCase().includes(searchTermTrimmed)) {

                if (!dateSet.has(invoice.id)) {

                    results['By Date'].push({

                        category: 'By Date',

                        invoice: invoice,

                        matchedText: formattedDate,

                        date: invoice.Date

                    });

                    dateSet.add(invoice.id);

                }

            }



            // By Description

            if (invoice.Description && invoice.Description.toLowerCase().includes(searchTermTrimmed)) {

                if (!descSet.has(invoice.id)) {

                    results['By Description'].push({

                        category: 'By Description',

                        invoice: invoice,

                        matchedText: invoice.Description

                    });

                    descSet.add(invoice.id);

                }

            }



            // By Amount

            const amountStr = invoice.Total.toString();

            if (amountStr.includes(searchTermTrimmed)) {

                if (!amountSet.has(invoice.id)) {

                    results['By Amount'].push({

                        category: 'By Amount',

                        invoice: invoice,

                        matchedText: `£${invoice.Total.toFixed(2)}`

                    });

                    amountSet.add(invoice.id);

                }

            }

        });



        setSearchResults(results);

    } catch (error) {

        console.error('Search error:', error);

        setSearchResults({});

    }

};

const handleDeleteDraft = async (id: string) => {
    try {
        await pb.collection('Invoice_Drafts').delete(id);
        setInvoiceDrafts(invoiceDrafts.filter((draft : any) => draft.id !== id));
    } catch (error) {
        console.error('Error deleting draft:', error);
    }
};



const highlightMatch = (text: string, searchTerm: string) => {

    if (!text || !searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, 'gi');

    const parts = text.split(regex);



    return (

        <span>

            {parts.map((part, i) =>

                regex.test(part) ?

                    <span key={i} className="Highlighted-Text">{part}</span> :

                    part

            )}

        </span>

    );

};


  const renderInvoiceList = !newInvoice && !openDraft && !openInvoice && !editInvoice && !uploadInvoice && !isLoading && !openPrompt && !onlyDrafts;
  const renderDraftList = !newInvoice && !openDraft && !openInvoice && !editInvoice && !uploadInvoice && !isLoading && !openPrompt && !onlyInvoices;

  useEffect(() => {
    if (!newInvoice && !openDraft && !openInvoice && !editInvoice && !isLoading && !openPrompt) {
        getDrafts()
        getInvoices()
    }
    
  },[newInvoice,openDraft,openInvoice,editInvoice,openPrompt]);

  useEffect(() => {
    calculateSubTotal()
  }, [lineItems]);

  useEffect(() => {
    calculateTotalAndBalanceDue()
  }, [invoiceSubTotal, invoiceTax, invoiceDiscount, invoiceShipping,invoiceAmountPaid,invoiceDiscountPercentage,invoiceShippingCost]);

  return (
    <div className="Invoice">
        <div className='Hull'>
            <div style={{flexDirection:'row',display:'flex',
            alignItems:'center',justifyContent:'space-between',width:'100%',borderBottom:'2px solid #07122463',marginBottom:'8px',paddingBottom:'8px'
            }}>
        {((newInvoice || openDraft || editInvoice) && !isLoading) && (<div className='Invoice-Options'>
            <motion.div  whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={closeInvoice} className='Back'>
                <FontAwesomeIcon color='#071224' size='3x' icon={faArrowLeft}/>
            </motion.div>
            <motion.div  whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={SaveInvoice} className='Option-Button'>
                <FontAwesomeIcon color='#071224' size='2x' icon={faSave}/>
                <h1>Save</h1>
            </motion.div>

            <PDFDownloadLink style={{textDecoration:"none"}} document={<MyDocument />} fileName={`Invoice ${invoiceNumber}.pdf`}>
            <motion.div  whileHover={{scale:1.1}} whileTap={{scale:0.8}} className='Option-Button'>
                <FontAwesomeIcon color='#071224' size='2x' icon={faDownload}/>
                <h1>Download</h1>
            </motion.div>
            </PDFDownloadLink>

            <motion.div  whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={handleSaveAndSendInvoice} className='Option-Button'>
                <FontAwesomeIcon color='#071224' size='2x' icon={faEnvelope}/>
                <h1>Send</h1>
            </motion.div>
            
        </div>)}
        {(openInvoice && !isLoading) && (<div className='Invoice-Options'>
            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.8}}  onClick={(identifier && closeView) ? closeView : closeInvoice} className='Back'>
                <FontAwesomeIcon color='#071224' size='3x' icon={faArrowLeft}/>
            </motion.div>
            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.8}}  onClick={() => handleEditInvoice(invoiceId)} className='Option-Button'>
                
                <FontAwesomeIcon color='#071224' size='2x' icon={faPencil}/>
                <h1>Edit</h1>
            </motion.div>
            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.8}}  onClick={handleSendInvoice} className='Option-Button'>
                <FontAwesomeIcon color='#071224' size='2x' icon={faEnvelope}/>
                <h1>Send</h1>
            </motion.div>
            
        </div>)}
        {(uploadInvoice && !isLoading) && (<div className='Invoice-Options'>
            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.8}}  onClick={() => setUploadInvoice(false)} className='Back'>
                <FontAwesomeIcon color='#071224' size='3x' icon={faArrowLeft}/>
            </motion.div>
            
        </div>)}
        {(openPrompt && !isLoading) && (<div className='Invoice-Options'>
            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.8}}  onClick={() => setOpenPrompt(false)} className='Back'>
                <FontAwesomeIcon color='#071224' size='3x' icon={faArrowLeft}/>
            </motion.div>
            
        </div>)}
            <motion.h1 layout className='Title'>Invoice Generator</motion.h1>
            {(!newInvoice && !openDraft && !openInvoice && !editInvoice && !uploadInvoice && !isLoading && !openPrompt) && (
                <div className='Invoice-Options'>
                <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:0.3}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={() => setUploadInvoice(true)} className='Option-Button'>
                <FontAwesomeIcon color='#071224' size='2x' icon={faUpload}/>
                <h1>Upload</h1>
            </motion.div>

            <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:0.3}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={() => setOpenPrompt(true)} className='Option-Button'>
                <FontAwesomeIcon color='#071224' size='2x' icon={faTachometerAltFast}/>
                <h1>Quick Invoice</h1>
            </motion.div>

            <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:0.3}} whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={handleNewInvoice} className='Option-Button'> 
                    <div className='Plus-Box'>
                    <h1>+</h1>
                    </div>
                <h1>Blank Invoice</h1>
            </motion.div>
                
            </div>
            )}
            </div>
            
            {( renderDraftList || renderInvoiceList )&&(
            <motion.div className="Invoices-Drafts-Container">
            
            {(renderDraftList && !searchTerm) && (
                <motion.div className='Lay-Div' layout>
            <motion.div layout initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} transition={{delay:1}} onClick={() => setOnlyDrafts(!onlyDrafts)} className='Line-Box'>
                <div className='Sectioner'></div>
                <h1 style={{paddingRight:"5px"}}>Drafts</h1>
                
            </motion.div>
            {invoiceDrafts.length == 0 && <h3 style={{alignSelf:'center',paddingTop:'10px',paddingBottom:'10px'}}>No Drafts</h3>}
            {invoiceDrafts.map((draft : any,index: any) => (
                    <motion.div title='Open Draft' initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1,transition:{delay:1.3}}}  onClick={() => handleOpenDraft(draft)} className='Draft-Line-Box'>
                        
                        <h2>Created On {formatDraftDate(draft.created)} | Invoice {draft.Number}</h2>
                        <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.8}}>
                        <FontAwesomeIcon icon={faTrash} size='2x' color='#ff4f4f' title='Delete Draft' onClick={(e) => { e.stopPropagation(); handleDeleteDraft(draft.id); }} />
                        </motion.div>
                        
                    </motion.div>
                ))}
                
            </motion.div>

            )}
            </motion.div>)}

            {(openPrompt && !isLoading) && (
                <div className='Prompt-Container'>
                    <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}}>
                        <h1>Describe your invoice using everyday language, with possible details such as name, address, date, and services rendered</h1>
                        
                        <input onChange={(e) => setInvoicePrompt(e.target.value)} placeholder='Example: Boiler repair invoice for John Q. Public, 456 Elm Avenue SW1A 1AA' type="text"/>

                        <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.8}} onClick={GenerateInvoice} className='Generate-Button'>
                        <h1>Generate Invoice</h1>
                        </motion.div>
                    </motion.div>
                </div>
            )}



            {isLoading && (
                <div className='Loading-Container'>
                    <Loading/>
                </div>
            )}
            
            {(uploadInvoice && !isLoading) && (
                <InvoiceUpload/>
            )}

            {((newInvoice || openDraft) && !isLoading)  && (
                    
                    <div className='Invoice-Generator'>
                        <div className='Invoice-Head'>
                            <div className='Invoice-Head-Left'>
                                <img src={imageUrl} className='Logo-Image'/>
                                <div onPointerEnter={() => setDisplayFromEdit(true)} onPointerLeave={() => setDisplayFromEdit(false)} className='Footer-Notes'>
                                {alteringFrom ? (
                                    <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringFrom(!alteringFrom)} title='Save' className='Save-Bubble'>
                                        <h2 style={{ color: Primary }}>Save</h2>
                                    </motion.div>
                                ) : (
                                    <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                        {displayFromEdit && (
                                            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringFrom(!alteringFrom)} title='Edit' className='Edit-Bubble'>
                                                <FontAwesomeIcon icon={faPencil} color={Primary} />
                                            </motion.div>
                                        )}
                                    </div>
                                )}
                                {window.innerWidth <= 800 && (
                                    alteringFrom ? (
                                        <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringFrom(!alteringFrom)} title='Save' className='Save-Bubble'>
                                            <h2 style={{ color: Primary }}>Save</h2>
                                        </motion.div>
                                    ) : (
                                        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                            
                                                <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringFrom(!alteringFrom)} title='Edit' className='Edit-Bubble'>
                                                    <FontAwesomeIcon icon={faPencil} color={Primary} />
                                                </motion.div>
                                            
                                        </div>
                                    )
                                )}
                                {alteringFrom ? <textarea onChange={(e) => handleFrom(e.target.value)} 
                                defaultValue={invoiceFrom} 
                                onBlur={handleSaveDraft} 
                                placeholder="From" 
                                name="" id=""/>
                                :
                                <h2 style={{ whiteSpace: 'pre-wrap' }}>{invoiceFrom || ''}</h2>
                                }
                            </div>
                                <h1>Bill to</h1>
                                
                                <CustomerTextPrediction  changed={handleBillTo} blurred={handleSaveDraft}/>
                            </div>
                            <div className='Invoice-Head-Right'>
                                <div className='Title-And-Number'>
                                <h1>INVOICE</h1>
                                <div className='Invoice-Number'>
                                    <h1>#</h1>
                                <input defaultValue={invoiceNumber || 0} onChange={(e) => handleNumber(e.target.value)} onBlur={handleSaveDraft} type="text" />
                                </div>
                                </div>

                                <div className='Invoice-Sub-Details'>
                                    <div>
                                    <h1>Date</h1>
                                    <input defaultValue={invoiceDate} onChange={(e) => handleDate(e.target.value)} onBlur={handleSaveDraft} type="date" />
                                    </div>
                                    <div>
                                    <h1>Payment Terms</h1>
                                    <input defaultValue={invoicePaymentTerms || ''} onChange={(e) => handlePaymentTerms(e.target.value)} onBlur={handleSaveDraft} type="text" />
                                    </div>
                                    <div>
                                    <h1>Due Date</h1>
                                    <input defaultValue={invoiceDueDate} onChange={(e) => handleDueDate(e.target.value)} onBlur={handleSaveDraft} type="date" />
                                    </div>
                                    <div>
                                    <h1>PO Number</h1>
                                    <input defaultValue={invoicePONumber || ''} onChange={(e) => handlePONumber(e.target.value)} onBlur={handleSaveDraft} type="text" />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='Invoice-Main'>
                            <div className="Invoice-Items">
                                <div className='Item-Title'>
                                    <h1>Item</h1>

                                </div>
                                <div className='Item-Info-Title'>
                                    <h1>Quantity</h1>

                                </div>
                                <div className='Item-Info-Title'>
                                <h1>Rate</h1>

                                </div>
                                <div className='Item-Info-Title'>
                                <h1>Amount</h1>

                                </div>

                            </div>
                            {lineItems.map((item, index) => (
                <div key={index} className='Invoice-Lines'>
                    <input 
                        placeholder='Description of item/service...' 
                        className='Item-Line' 
                        type="text"
                        value={item.description}
                        onChange={(e) => handleChange(index, 'description', e.target.value)}
                       
                    />
                    <input 
                        className='Item-Info-Line' 
                        type="number"
                        value={item.quantity}
                        
                        onChange={(e) => handleChange(index, 'quantity', e.target.value)}
                    />
                    <div className='Item-Rate-Line'>
                        <h1>£</h1>
                        <input 
                            type="number" 
                            value={item.rate}
                            onChange={(e) => handleChange(index, 'rate', e.target.value)}
                            
                        />
                    </div>
                    <div className='Item-Amount-Line'>
                        <h1 style={{color:'#484c50', opacity:0.75}}>{item.rate * item.quantity > 0 ? 'GBP£ ' + (item.quantity * item.rate).toFixed(2) : 'GBP£ 0.00'  }</h1>
                    </div>
                    <div onClick={() => handleRemoveLine(index)} style={{cursor:'pointer',border:"1px darkred solid",borderRadius:'3px'}} className='Item-Delete-Line'>
                        <FontAwesomeIcon color='darkred' icon={faXmark}/>
                    </div>
                </div>
            ))}
            <div className='Add-Line'>
                <button onClick={handleAddLine}>
                   + Add Item
                </button>
            </div>
            <div className='Invoice-Foot'>
                <div className='Invoice-Foot-Left'>
                    <h1>Notes</h1>
                    <div onPointerEnter={() => setDisplayNotesEdit(true)} onPointerLeave={() => setDisplayNotesEdit(false)} className='Footer-Notes'>
                        {alteringNotes ? (
                            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringNotes(!alteringNotes)} title='Save' className='Save-Bubble'>
                                <h2 style={{ color: Primary }}>Save</h2>
                            </motion.div>
                        ) : (
                            <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                {displayNotesEdit && (
                                    <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringNotes(!alteringNotes)} title='Edit' className='Edit-Bubble'>
                                        <FontAwesomeIcon icon={faPencil} color={Primary} />
                                    </motion.div>
                                )}
                            </div>
                        )}
                        {window.innerWidth <= 800 && ( 
                            alteringNotes ? (
                                <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringNotes(!alteringNotes)} title='Save' className='Save-Bubble'>
                                    <h2 style={{ color: Primary }}>Save</h2>
                                </motion.div>
                            ) : (
                                <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                   
                                        <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringNotes(!alteringNotes)} title='Edit' className='Edit-Bubble'>
                                            <FontAwesomeIcon icon={faPencil} color={Primary} />
                                        </motion.div>
                                    
                                </div>
                            ))}
                        {alteringNotes ? <textarea onChange={(e) => handleNotes(e.target.value)} 
                        defaultValue={invoiceNotes} 
                        onBlur={handleSaveDraft} 
                        placeholder="Notes - any information not covered" 
                        name="" id=""/>
                        :
                        <h2 style={{ whiteSpace: 'pre-wrap' }}>{invoiceNotes || ''}</h2>
                        }
                    </div>
                    
                    <h1>Terms</h1>
                    <textarea onChange={(e) => handleTerms(e.target.value)} defaultValue={invoiceTerms} onBlur={handleSaveDraft} placeholder="Terms and Conditions - late fees, payment method, delivery schedule" name="" id=""/>

                </div>

                <div className='Invoice-Foot-Right'>
                    <div className="Invoice-Total">
                        <h1>Subtotal</h1>

                        <h1>GBP£ {invoiceSubTotal.toFixed(2)}</h1>

                    </div>
                    <div className='Invoice-Tax'>
                        <h1>Tax</h1>

                        <div className='Tax-Box'>
                            <div className='Tax-Box-Left'>
                                <input onChange={(e) => handleTax(e.target.value)} onBlur={handleSaveDraft} defaultValue={invoiceTax} type="text" />

                                <h1>%</h1>
                            </div>
                            <div className='Tax-Box-Right'>
                                <FontAwesomeIcon size='lg' opacity={0.55} icon={faArrowsSpin}/>


                            </div>
                        </div>
                    </div>
                    {discount && (
                        <div className='Invoice-Tax'>
                        <h1>Discount</h1>

                        <div className='Tax-Box'>
                            <div className='Tax-Box-Left'>
                                <input onChange={(e) => handleDiscount(e.target.value)} onBlur={handleSaveDraft} defaultValue={invoiceDiscountPercentage} type="text" />

                                <h1>%</h1>
                            </div>
                            <div className='Tax-Box-Right'>
                                <FontAwesomeIcon size='lg' opacity={0.55} icon={faArrowsSpin}/>


                            </div>
                        </div>
                    </div>
                    )}
                    {shipping && (
                        <div className='Invoice-Tax'>
                        <h1>Shipping</h1>

                        <div className='Paid-Box'>
                            <h1>£</h1>
                            <input onChange={(e) => handleShipping(e.target.value)} onBlur={handleSaveDraft} defaultValue={invoiceShippingCost} type="text" />

                                
                            
                            
                        </div>
                    </div>
                    )}

                    {(!discount || !shipping) && (<div className="Invoice-Total">
                        {!discount && (<h1 onClick={() => setDiscount(true)} style={{color:'#169120',cursor:'pointer'}}>+Discount</h1>)}

                        {!shipping && (<h1 onClick={() => setShipping(true)} style={{color:'#169120',cursor:'pointer'}}>+Shipping</h1>)}

                    </div>)}

                    <div className="Invoice-Total">
                        <h1 >Total</h1>

                        <h1>GBP£ {invoiceTotal.toFixed(2)}</h1>

                    </div>
                    <div className='Invoice-Paid'>
                        <h1>Amount Paid</h1>

                        <div className='Paid-Box'>
                            <h1>£</h1>
                            <input onChange={(e) => handleAmountPaid(e.target.value)} onBlur={handleSaveDraft} defaultValue={invoiceAmountPaid} type="text" />

                                
                            
                            
                        </div>
                    </div>
                    <div className="Invoice-Total">
                        <h1 >Balance Due</h1>

                        <h1>GBP£ {invoiceBalanceDue.toFixed(2)}</h1>

                    </div>

                </div>

            </div>
            

                        </div>
                        

                    </div>
                
                )}
                {(editInvoice && !isLoading) && (
                    
                    <div className='Invoice-Generator'>
                        <div className='Invoice-Head'>
                            <div className='Invoice-Head-Left'>
                                <img src={imageUrl} className='Logo-Image'/>
                                <div onPointerEnter={() => setDisplayFromEdit(true)} onPointerLeave={() => setDisplayFromEdit(false)} className='Footer-Notes'>
                                {alteringFrom ? (
                                    <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringFrom(!alteringFrom)} title='Save' className='Save-Bubble'>
                                        <h2 style={{ color: Primary }}>Save</h2>
                                    </motion.div>
                                ) : (
                                    <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                        {displayFromEdit && (
                                            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringFrom(!alteringFrom)} title='Edit' className='Edit-Bubble'>
                                                <FontAwesomeIcon icon={faPencil} color={Primary} />
                                            </motion.div>
                                        )}
                                    </div>
                                )}
                                {window.innerWidth <= 800 && (
                                    alteringFrom ? (
                                        <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringFrom(!alteringFrom)} title='Save' className='Save-Bubble'>
                                            <h2 style={{ color: Primary }}>Save</h2>
                                        </motion.div>
                                    ) : (
                                        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                            
                                                <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringFrom(!alteringFrom)} title='Edit' className='Edit-Bubble'>
                                                    <FontAwesomeIcon icon={faPencil} color={Primary} />
                                                </motion.div>
                                            
                                        </div>
                                    )
                                )}
                                {alteringFrom ? <textarea onChange={(e) => handleFrom(e.target.value)} 
                                defaultValue={invoiceFrom} 
                                onBlur={handleSaveInvoice} 
                                placeholder="From" 
                                name="" id=""/>
                                :
                                <h2 style={{ whiteSpace: 'pre-wrap' }}>{invoiceFrom || ''}</h2>
                                }
                            </div>
                                <h1>Bill to</h1>
                            
                                <CustomerTextPrediction  changed={handleBillTo} blurred={handleSaveInvoice}/>
                            </div>
                            <div className='Invoice-Head-Right'>
                                <div className='Title-And-Number'>
                                <h1>INVOICE</h1>
                                <div className='Invoice-Number'>
                                    <h1>#</h1>
                                <input defaultValue={invoiceNumber || 0} onChange={(e) => handleNumber(e.target.value)} onBlur={handleSaveInvoice} type="text" />
                                </div>
                                </div>

                                <div className='Invoice-Sub-Details'>
                                    <div>
                                    <h1>Date</h1>
                                    <input defaultValue={invoiceDate} onChange={(e) => handleDate(e.target.value)} onBlur={handleSaveInvoice} type="date" />
                                    </div>
                                    <div>
                                    <h1>Payment Terms</h1>
                                    <input defaultValue={invoicePaymentTerms || ''} onChange={(e) => handlePaymentTerms(e.target.value)} onBlur={handleSaveInvoice} type="text" />
                                    </div>
                                    <div>
                                    <h1>Due Date</h1>
                                    <input defaultValue={invoiceDueDate} onChange={(e) => handleDueDate(e.target.value)} onBlur={handleSaveInvoice} type="date" />
                                    </div>
                                    <div>
                                    <h1>PO Number</h1>
                                    <input defaultValue={invoicePONumber || ''} onChange={(e) => handlePONumber(e.target.value)} onBlur={handleSaveInvoice} type="text" />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='Invoice-Main'>
                            <div className="Invoice-Items">
                                <div className='Item-Title'>
                                    <h1>Item</h1>

                                </div>
                                <div className='Item-Info-Title'>
                                    <h1>Quantity</h1>

                                </div>
                                <div className='Item-Info-Title'>
                                <h1>Rate</h1>

                                </div>
                                <div className='Item-Info-Title'>
                                <h1>Amount</h1>

                                </div>

                            </div>
                            {lineItems.map((item, index) => (
                <div key={index} className='Invoice-Lines'>
                    <input 
                        placeholder='Description of item/service...' 
                        className='Item-Line' 
                        type="text"
                        value={item.description}
                        onChange={(e) => handleChange(index, 'description', e.target.value)}
                       
                    />
                    <input 
                        className='Item-Info-Line' 
                        type="number"
                        value={item.quantity}
                        
                        onChange={(e) => handleChange(index, 'quantity', e.target.value)}
                    />
                    <div className='Item-Rate-Line'>
                        <h1>£</h1>
                        <input 
                            type="number" 
                            value={item.rate}
                            onChange={(e) => handleChange(index, 'rate', e.target.value)}
                            
                        />
                    </div>
                    <div className='Item-Amount-Line'>
                        <h1 style={{color:'#484c50', opacity:0.75}}>{item.rate * item.quantity > 0 ? 'GBP£ ' + (item.quantity * item.rate).toFixed(2) : 'GBP£ 0.00'  }</h1>
                    </div>
                    <div onClick={() => handleRemoveLine(index)} style={{cursor:'pointer',border:"1px darkred solid",borderRadius:'3px'}} className='Item-Delete-Line'>
                        <FontAwesomeIcon color='darkred' icon={faXmark}/>
                    </div>
                </div>
            ))}
            <div className='Add-Line'>
                <button onClick={handleAddLine}>
                   + Add Item
                </button>
            </div>
            <div className='Invoice-Foot'>
                <div className='Invoice-Foot-Left'>
                    <h1>Notes</h1>
                    <div onPointerEnter={() => setDisplayNotesEdit(true)} onPointerLeave={() => setDisplayNotesEdit(false)} className='Footer-Notes'>
                        {alteringNotes ? (
                            <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringNotes(!alteringNotes)} title='Save' className='Save-Bubble'>
                                <h2 style={{ color: Primary }}>Save</h2>
                            </motion.div>
                        ) : (
                            <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                {displayNotesEdit && (
                                    <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringNotes(!alteringNotes)} title='Edit' className='Edit-Bubble'>
                                        <FontAwesomeIcon icon={faPencil} color={Primary} />
                                    </motion.div>
                                )}
                            </div>
                        )}
                        {window.innerWidth <= 800 && ( 
                            alteringNotes ? (
                                <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringNotes(!alteringNotes)} title='Save' className='Save-Bubble'>
                                    <h2 style={{ color: Primary }}>Save</h2>
                                </motion.div>
                            ) : (
                                <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                   
                                        <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.85}} onClick={() => setAlteringNotes(!alteringNotes)} title='Edit' className='Edit-Bubble'>
                                            <FontAwesomeIcon icon={faPencil} color={Primary} />
                                        </motion.div>
                                    
                                </div>
                            ))}
                        {alteringNotes ? <textarea onChange={(e) => handleNotes(e.target.value)} 
                        defaultValue={invoiceNotes} 
                        onBlur={handleSaveDraft} 
                        placeholder="Notes - any information not covered" 
                        name="" id=""/>
                        :
                        <h2 style={{ whiteSpace: 'pre-wrap' }}>{invoiceNotes || ''}</h2>
                        }
                    </div>
                    <h1>Terms</h1>
                    <textarea onChange={(e) => handleTerms(e.target.value)} defaultValue={invoiceTerms} onBlur={handleSaveInvoice} placeholder="Terms and Conditions - late fees, payment method, delivery schedule" name="" id=""/>

                </div>

                <div className='Invoice-Foot-Right'>
                    <div className="Invoice-Total">
                        <h1>Subtotal</h1>

                        <h1>GBP£ {invoiceSubTotal.toFixed(2)}</h1>

                    </div>
                    <div className='Invoice-Tax'>
                        <h1>Tax</h1>

                        <div className='Tax-Box'>
                            <div className='Tax-Box-Left'>
                                <input onChange={(e) => handleTax(e.target.value)} onBlur={handleSaveInvoice} defaultValue={invoiceTax} type="text" />

                                <h1>%</h1>
                            </div>
                            <div className='Tax-Box-Right'>
                                <FontAwesomeIcon size='lg' opacity={0.55} icon={faArrowsSpin}/>


                            </div>
                        </div>
                    </div>
                    {discount && (
                        <div className='Invoice-Tax'>
                        <h1>Discount</h1>

                        <div className='Tax-Box'>
                            <div className='Tax-Box-Left'>
                                <input onChange={(e) => handleDiscount(e.target.value)} onBlur={handleSaveInvoice} defaultValue={invoiceDiscountPercentage} type="text" />

                                <h1>%</h1>
                            </div>
                            <div className='Tax-Box-Right'>
                                <FontAwesomeIcon size='lg' opacity={0.55} icon={faArrowsSpin}/>


                            </div>
                        </div>
                    </div>
                    )}
                    {shipping && (
                        <div className='Invoice-Tax'>
                        <h1>Shipping</h1>

                        <div className='Paid-Box'>
                            <h1>£</h1>
                            <input onChange={(e) => handleShipping(e.target.value)} onBlur={handleSaveInvoice} defaultValue={invoiceShippingCost} type="text" />

                                
                            
                            
                        </div>
                    </div>
                    )}

                    {(!discount || !shipping) && (<div className="Invoice-Total">
                        {!discount && (<h1 onClick={() => setDiscount(true)} style={{color:'#169120',cursor:'pointer'}}>+Discount</h1>)}

                        {!shipping && (<h1 onClick={() => setShipping(true)} style={{color:'#169120',cursor:'pointer'}}>+Shipping</h1>)}

                    </div>)}

                    <div className="Invoice-Total">
                        <h1 >Total</h1>

                        <h1>GBP£ {invoiceTotal.toFixed(2)}</h1>

                    </div>
                    <div className='Invoice-Paid'>
                        <h1>Amount Paid</h1>

                        <div className='Paid-Box'>
                            <h1>£</h1>
                            <input onChange={(e) => handleAmountPaid(e.target.value)} onBlur={handleSaveInvoice} defaultValue={invoiceAmountPaid} type="text" />

                                
                            
                            
                        </div>
                    </div>
                    <div className="Invoice-Total">
                        <h1 >Balance Due</h1>

                        <h1>GBP£ {invoiceBalanceDue.toFixed(2)}</h1>

                    </div>

                </div>

            </div>
            

                        </div>
                        

                    </div>
                
                )}

            {(openInvoice && !isLoading) && (   
                <>
                
                    <InvoiceViewer pdfUrl={pdfUrl}/>
                
                </>
            )}
        </div>
      
    </div>
  );
}

export default InvoiceGenerator;
