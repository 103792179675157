import React, {useState, useEffect} from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import InvLogo from '../GlobalComponents/InvLogo';
import './Login.css';
import { Primary, Secondary, Tertiary } from '../Constants/Colours';
import Logo from '../GlobalComponents/Logo';
import WhiteButton from '../GlobalComponents/WhiteButton';
import {motion, AnimatePresence} from 'framer-motion'
import PocketBase from 'pocketbase'



interface LoginProps {
  setAuth: (auth:boolean) => void;
}

const Login: React.FC<LoginProps> = ({setAuth}) => {

  const [username,setUsername] = useState('')
  const [password,setPassword] = useState('')
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);

  const pb = new PocketBase('https://admin.aaatheatingandgas.co.uk/aaatbase');
  const user = process.env.REACT_APP_USER || ''
  const pass = process.env.REACT_APP_PASS || ''
  pb.admins.authWithPassword(user,pass)



  const handleLogin = async () => {
    const checkuser = await pb.collection('Users').getFirstListItem(`Username = '${username}'`)
    if (password == checkuser.Password) {
        setAuth(true)
        if (keepLoggedIn){
        localStorage.setItem('isLoggedIn', 'true');
       }
    } else {
        
    }
  }

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    if (loggedIn === 'true') {
      setAuth(true);
    }
  }, [setAuth]);
  
  const Consent = () => {
    setKeepLoggedIn(!keepLoggedIn)
  }

  

  const handleUsername = (text: any) => {
    setUsername(text)
  }

  const handlePassword = (text: any) => {
    setPassword(text)
  }



  return (
      <AnimatePresence>
        <motion.div initial={{y:'-10%',opacity:0}} animate={{y:'0%',opacity:1}} exit={{scale:0.2,opacity:0}} transition={{duration:1.5}} className='Login-Form-Container'>
            <div>
                <InvLogo color={Primary} size={0.7}/>
                <h1>Login</h1>
                <div className='Form'>
                    <div>
                    <h2>Username</h2>
                    <input onChange={(event) => handleUsername(event?.target.value)} type="text" />
                    </div>
                    <div>
                    <h2>Password</h2>
                    <input onChange={(event) => handlePassword(event?.target.value)} type="password" />
                    </div>
                    <section className="Remember-Row">
                  <motion.section whileTap={{scale:0.7}} whileHover={{scale:0.9}} onClick={Consent} className="Remember-Box">
                    {keepLoggedIn && (<InvLogo size={0.35} color={Primary}/>)}
                  </motion.section>
                      <p>Keep Logged In on this Device.</p>
                    </section>
                    <WhiteButton onClick={handleLogin} width={100} height={40} text={'Login'} arrow={true} darkMode={true}/>
                </div>

            </div>
        </motion.div>
        </AnimatePresence>
  );
}

export default Login;
